import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import Milestone from "../../components/dashboard/Milestone.json";

import "./trackmap.scss";

export class AuditFiling extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      status: false,
      step: 0,
      data: "",
      businessType: "",
      noOfPartners: "",
      auditStatus: "Yes",
      incorporationCertificate: "",
      authorizedCapital: "",
      paidUpCapital: "",
      partnershipAgreement: "",
      transID: "",
      transIDStat: "",
      error: null,
      success: null,
    };

    this.handleAuditFiling = this.handleAuditFiling.bind(this);
    this.handleTransID = this.handleTransID.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.success) {
        if (
          data.data.auditFiling?.transactionID === "" ||
          !data.data.auditFiling?.transactionID
        )
          this.setState({ transIDStat: true });
        else this.setState({ transIDStat: false });
        this.setState({
          status: data.data.auditFiling?.status,
          businessType: data.data.businessType,
          step: data.data.auditFiling?.step,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault();

    this.setState({ transIDStat: false });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`,
      {
        token: localStorage.getItem("token"),
        service: "auditFiling",
        transID: this.state.transID,
      }
    );

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
    } else {
      this.setState({ error: "Something went wrong. Please try again." });
    }
  }

  async handleAuditFiling(event) {
    event.preventDefault();

    this.setState({ loading: false });

    const formData = new FormData();

    formData.append("noOfPartners", this.state.noOfPartners);
    formData.append("auditStatus", this.state.auditStatus);
    if (this.state.authorizedCapital || this.state.authorizedCapital !== "")
      formData.append("authorizedCapital", this.state.authorizedCapital);
    if (this.state.paidUpCapital || this.state.paidUpCapital !== "")
      formData.append("paidUpCapital", this.state.paidUpCapital);
    if (
      this.state.incorporationCertificate ||
      this.state.incorporationCertificate !== ""
    )
      formData.append(
        "incorporationCertificate",
        this.state.incorporationCertificate
      );
    if (this.state.partnershipAgreement || this.state.partnershipAgreement)
      formData.append("partnershipAgreement", this.state.partnershipAgreement);
    formData.append("token", localStorage.getItem("token"));

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/audit-filing`,
      formData
    );

    if (data.success) {
      this.setState({ status: true });
      this.setState({ success: "Audit Filing documents submitted" });
      this.setState({ step: 0 });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/service/audit-filing";
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">Audit Filing</h1>
            {this.state.status ? (
              <div className="track-map">
                {Milestone["auditfiling"].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div
                            className={
                              "vertical-divider " + (index === 11 ? "last" : "")
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className={
                          "invite-subtitle " +
                          (index <= this.state.step ? "active " : "")
                        }
                      >
                        <h5>{ms}</h5>
                        {this.state.step === 3 &&
                        index === 3 &&
                        this.state.transIDStat ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input
                                placeholder="Transaction ID"
                                type="text"
                                onChange={(event) =>
                                  this.setState({ transID: event.target.value })
                                }
                              ></input>
                              <button className="button" type="submit">
                                Submit
                              </button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleAuditFiling}>
                  <div>
                    <label>No. of Partners/Shareholders</label>
                    <input
                      type="text"
                      value={this.state.noOfPartners}
                      onChange={(event) =>
                        this.setState({ noOfPartners: event.target.value })
                      }
                      required
                    />
                  </div>

                  {this.state.businessType === "Partnership" ? (
                    <div>
                      <label>Partnership Agreement</label>
                      <input
                        type="file"
                        onChange={(event) =>
                          this.setState({
                            partnershipAgreement: event.target.files[0],
                          })
                        }
                        required
                      />
                    </div>
                  ) : null}

                  <label>Is the audit complete?</label>
                  <select
                    value={this.state.auditStatus}
                    onChange={(event) =>
                      this.setState({ auditStatus: event.target.value })
                    }
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

                  <label>Incorporation Certificate</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({
                        incorporationCertificate: event.target.files[0],
                      })
                    }
                    required
                  />

                  {this.state.businessType === "Private LTD" ? (
                    <div>
                      <label>Authorized Capital</label>
                      <input
                        type="text"
                        value={this.state.authorizedCapital}
                        onChange={(event) =>
                          this.setState({
                            authorizedCapital: event.target.value,
                          })
                        }
                        required
                      />

                      <label>Paid-up Capital</label>
                      <input
                        type="text"
                        value={this.state.paidUpCapital}
                        onChange={(event) =>
                          this.setState({ paidUpCapital: event.target.value })
                        }
                        required
                      />
                    </div>
                  ) : null}

                  <button className="button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AuditFiling;
