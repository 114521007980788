import axios from 'axios'
import React, { Component } from 'react'
import { SpinnerCircular } from 'spinners-react'

import Error from '../../components/error/error'
import Success from '../../components/error/success'

import Milestone from '../../components/dashboard/Milestone.json'

import './trackmap.scss'

export class MonthlyVat extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      data: '',
      businessType: '',
      companyName: '',
      companyType: '',
      probableIncome: '',
      error: null,
      success: null,
      transIDStat: true,
      transID: ''
    }
    
    this.handleVAT = this.handleVAT.bind(this)
    this.handleTransID = this.handleTransID.bind(this)
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business/get`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (data.success) {
        if (data.data.vatFiling?.transactionID === '' || !data.data.vatFiling?.transactionID) this.setState({ transIDStat: true })
        else this.setState({ transIDStat: false })
        this.setState({ status: data.data.vatFiling?.status, businessType: data.data.businessType, step: data.data.vatFiling?.step, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault()

    this.setState({ transIDStat: false })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`, {
      token: localStorage.getItem('token'),
      service: 'vatFiling',
      transID: this.state.transID
    })

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
    } else {
      this.setState({ error: 'Something went wrong. Please try again.' })
    }
  }

  async handleVAT(event) {
    event.preventDefault();

    this.setState({ loading: true })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/vat-filing`, { companyName: this.state.companyName, companyType: this.state.companyType, probableIncome: this.state.probableIncome, token: localStorage.getItem('token') }, { headers: { 'Content-Type': 'application/json' } })

    console.log(data);
    if (data.success) {
      this.setState({ status: true })
      this.setState({ success: 'Monthly Vat Filing documents submitted'})
      this.setState({ step: 0 })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
      window.location.href = '/service/vat-filing'
    }
  }

  render() {
    return (
      <div className='trade-license'>
        { (this.state.error) ? <Error message={this.state.error}></Error> : null }
        { (this.state.success) ? <Success message={this.state.success}></Success> : null }
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className="container">
            <h1 className='serviceHeading'>Monthly Vat Filing</h1>
            { (this.state.status) ? (
              <div className="track-map">
                {Milestone['perMonthVatFiling'].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div className={'vertical-divider ' + (index === 8 ? 'last' : '')}></div>
                        </div>
                      </div>
                      <div className={'invite-subtitle ' + (index <= this.state.step ? 'active ' : '')}>
                        <h5>{ms}</h5>
                        {(this.state.step === 3 && index === 3 && this.state.transIDStat) ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input placeholder='Transaction ID' type='text' onChange={(event) => this.setState({ transID: event.target.value })}></input>
                              <button className='button' type='submit'>Submit</button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleVAT}>
                  <label>{(this.state.businessType === 'Sole proprietorship') ? <span>Proprietorship Name*</span> : (this.state.businessType === 'Partnership') ? <span>Partnership Firm Name*</span> : (this.state.businessType === 'Private LTD') ? <span>Company Name*</span> : (this.state.businessType === 'One Person Company') ? <span>One Person Company Name*</span> : ''}</label>
                  <input type="text" required onChange={(event) => this.setState({ companyName: event.target.value })} />

                  <label>Category(ies) of business (According to BIN {(this.state.businessType === 'Sole proprietorship') ? <span>and Trade License</span> : ''})*</label>
                  <input type="text" required onChange={(event) => this.setState({ companyType: event.target.value })} />

                  <label>Probable income in a month*</label>
                  <input type="text" required onChange={(event) => this.setState({ probableIncome: event.target.value })} />

                  <button className="button" type='submit'>Submit</button>
                </form>
              </div>
            ) }
          </div>
        )}
      </div>
    )
  }
}

export default MonthlyVat 