import React, { Component } from 'react'

import './sidebar.scss'

export default class BusinessSidebar extends Component {
  render() {
    return (
        <div className="navigation">
          <div className="side">
            <div className={`navLink ${(this.props.active === 'Business Activity') ? 'active' : ''} first`}>
              <div className="text">Business Activity</div>
            </div>

            <div className={`navLink ${(this.props.active === 'Description') ? 'active' : ''}`}>
              <div className="text">Address</div>
            </div>

            <div className={`navLink ${(this.props.active === 'Position') ? 'active' : ''}`}>
              <div className="text">Position</div>
            </div>


            <div className={`navLink ${(this.props.active === 'Company name') ? 'active' : ''}`}>
              <div className="text">Company name</div>
            </div>

            <div className={`navLink ${(this.props.active === 'Summary') ? 'active' : ''} last`}>
              <div className="text">Summary</div>
            </div>
          </div>
        </div>
    )
  }
}
