import axios from "axios";
import React, { Component } from "react";
import { SpinnerCircular } from "spinners-react";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import Milestone from "../../components/dashboard/Milestone.json";

import "./trackmap.scss";

export class ERC extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      data: "",
      businessType: "",
      tradeLicense: "",
      msCertificate: "",
      btCertificate: "",
      tin: "",
      passportPicture: "",
      nid: "",
      partnershipDocument: "",
      AOA: "",
      iCertificate: "",
      partnershipType: "",
      noOfPartners: "",
      error: null,
      success: null,
      transIDStat: true,
      transID: "",
    };

    this.handleERC = this.handleERC.bind(this);
    this.handleTransID = this.handleTransID.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(localStorage.getItem("token"));
      if (data.success) {
        if (
          data.data.erc?.transactionID === "" ||
          !data.data.erc?.transactionID
        )
          this.setState({ transIDStat: true });
        else this.setState({ transIDStat: false });
        this.setState({
          status: data.data.erc?.status,
          businessType: data.data.businessType,
          step: data.data.erc?.step,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault();

    this.setState({ transIDStat: false });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`,
      {
        token: localStorage.getItem("token"),
        service: "erc",
        transID: this.state.transID,
      }
    );

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
    } else {
      this.setState({ error: "Something went wrong. Please try again." });
    }
  }

  async handleERC(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("tradeLicense", this.state.tradeLicense);
    formData.append("msCertificate", this.state.msCertificate);
    formData.append("btCertificate", this.state.btCertificate);
    formData.append("tin", this.state.tin);
    formData.append("passportPicture", this.state.passportPicture);
    formData.append("nid", this.state.nid);
    formData.append("treasuryShipment", this.state.treasuryShipment);
    formData.append("token", localStorage.getItem("token"));

    if (this.state.partnershipType !== "")
      formData.append("partnershipType", this.state.partnershipType);
    if (this.state.noOfPartners !== "")
      formData.append("noOfPartners", this.state.noOfPartners);
    if (this.state.iCertificate !== "")
      formData.append("iCertificate", this.state.iCertificate);
    if (this.state.partnershipDocument !== "")
      formData.append("partnershipDocument", this.state.partnershipDocument);
    if (this.state.AOA !== "") formData.append("AOA", this.state.AOA);

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/erc`,
      formData
    );

    if (data.success) {
      this.setState({ status: true });
      this.setState({ success: "ERC documents submitted" });
      this.setState({ step: 0 });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/service/erc";
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">ERC License</h1>
            {this.state.status ? (
              <div className="track-map">
                {Milestone["erc"].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div
                            className={
                              "vertical-divider " + (index === 9 ? "last" : "")
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className={
                          "invite-subtitle " +
                          (index <= this.state.step ? "active " : "")
                        }
                      >
                        <h5>{ms}</h5>
                        {this.state.step === 3 &&
                        index === 3 &&
                        this.state.transIDStat ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input
                                placeholder="Transaction ID"
                                type="text"
                                onChange={(event) =>
                                  this.setState({ transID: event.target.value })
                                }
                              ></input>
                              <button className="button" type="submit">
                                Submit
                              </button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleERC}>
                  <label>
                    Trade License{" "}
                    {this.state.businessType === "Partnership"
                      ? "of partnership business*"
                      : this.state.businessType === "Sole proprietorship"
                      ? "of the proprietorship business*"
                      : this.state.businessType === "Private LTD"
                      ? "of the company*"
                      : this.state.businessType === "One Person Company"
                      ? "of the OPC*"
                      : ""}
                  </label>
                  <input
                    type="file"
                    required
                    onChange={(event) =>
                      this.setState({ tradeLicense: event.target.files[0] })
                    }
                  />

                  <label>
                    Valid membership certificate of a chamber of commerce/trade
                    association
                  </label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ msCertificate: event.target.files[0] })
                    }
                  />

                  <label>
                    Bank solvency certificate{" "}
                    {this.state.businessType === "Partnership"
                      ? "of partnership business"
                      : this.state.businessType === "Sole proprietorship"
                      ? "of the proprietorship business"
                      : this.state.businessType === "Private LTD"
                      ? "of the company"
                      : this.state.businessType === "One Person Company"
                      ? "of the OPC"
                      : ""}
                  </label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ btCertificate: event.target.files[0] })
                    }
                  />

                  <label>
                    TIN{" "}
                    {this.state.businessType === "Partnership"
                      ? "of partnership business"
                      : this.state.businessType === "Sole proprietorship"
                      ? "of the proprietor"
                      : this.state.businessType === "Private LTD"
                      ? "of the company"
                      : this.state.businessType === "One Person Company"
                      ? "of the OPC"
                      : ""}
                  </label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ tin: event.target.files[0] })
                    }
                  />

                  <label>
                    Passport size picture{" "}
                    {this.state.businessType === "Partnership"
                      ? "of partner"
                      : this.state.businessType === "Sole proprietorship"
                      ? "of the proprietor"
                      : this.state.businessType === "Private LTD"
                      ? "of the Managing Director/Chairman"
                      : this.state.businessType === "One Person Company"
                      ? "of the shareholder"
                      : ""}
                  </label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ passportPicture: event.target.files[0] })
                    }
                  />

                  <label>
                    NID{" "}
                    {this.state.businessType === "Partnership"
                      ? "of partner*"
                      : this.state.businessType === "Sole proprietorship"
                      ? "of the proprietor"
                      : this.state.businessType === "Private LTD"
                      ? "of the Managing Director/Chairman"
                      : this.state.businessType === "One Person Company"
                      ? "of the shareholder"
                      : ""}
                  </label>
                  <input
                    type="file"
                    required={this.state.businessType === "Partnership"}
                    onChange={(event) =>
                      this.setState({ nid: event.target.files[0] })
                    }
                  />

                  <label>
                    BIN{" "}
                    {this.state.businessType === "Partnership"
                      ? "of partnership business"
                      : this.state.businessType === "Sole proprietorship"
                      ? "of the proprietorship business"
                      : this.state.businessType === "Private LTD"
                      ? "of the company"
                      : this.state.businessType === "One Person Company"
                      ? "of the OPC"
                      : ""}
                  </label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ nidHouseOwner: event.target.files[0] })
                    }
                  />

                  {this.state.businessType === "Private LTD" ? (
                    <div className="partnership">
                      <label>
                        Article of Association & Memorandum of Association
                      </label>
                      <input
                        type="file"
                        onChange={(event) =>
                          this.setState({ AOA: event.target.files[0] })
                        }
                      />

                      <label>Incorporation Certificate</label>
                      <input
                        type="file"
                        onChange={(event) =>
                          this.setState({ iCertificate: event.target.files[0] })
                        }
                      />
                    </div>
                  ) : null}

                  {this.state.businessType === "Partnership" ? (
                    <div className="partnership">
                      <label>Select the type of partnership</label>
                      <br />
                      <select
                        defaultValue={this.state.partnershipType}
                        onChange={(event) =>
                          this.setState({ partnershipType: event.target.value })
                        }
                      >
                        <option value="General Partnership">
                          General Partnership
                        </option>
                        <option value="Limited Partnership">
                          Limited Partnership
                        </option>
                      </select>
                      <br />

                      {this.state.partnershipType === "Limited Partnership" ? (
                        <div className="parntership">
                          <label>Incorporation Certificate</label>
                          <input
                            type="file"
                            onChange={(event) =>
                              this.setState({
                                iCertificate: event.target.files[0],
                              })
                            }
                          />
                        </div>
                      ) : null}

                      <label>Partnership Agreement</label>
                      <input
                        type="file"
                        onChange={(event) =>
                          this.setState({
                            partnershipDocument: event.target.files[0],
                          })
                        }
                      />

                      <label>Number of partners*</label>
                      <input
                        type="text"
                        required
                        onChange={(event) =>
                          this.setState({ noOfPartners: event.target.value })
                        }
                      ></input>
                    </div>
                  ) : null}

                  <button className="button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ERC;
