import React, { Component } from 'react'
import axios from 'axios'

import './myAccount.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

export class MyAccount extends Component {
  constructor() {
    super()

    this.state = {
      data: '',
      phoneNumber: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      toggleCurrentPassword: 'password',
      toggleNewPassword: 'password',
      toggleConfirmNewPassword: 'password',
      error: '',
      success: ''
    }

    this.handleNumber = this.handleNumber.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.togglePassword = this.togglePassword.bind(this)
  }

  togglePassword(type) {
    console.log('run', type)
    if (type === 'currentPassword') {
      if (this.state.toggleCurrentPassword === 'password') return this.setState({ toggleCurrentPassword: 'text' })
      this.setState({ toggleCurrentPassword: 'password' })
    }
    if (type === 'newPassword') {
      if (this.state.toggleNewPassword === 'password') return this.setState({ toggleNewPassword: 'text' })
      this.setState({ toggleNewPassword: 'password' })
    }
    if (type === 'confirmNewPassword') {
      if (this.state.toggleConfirmNewPassword === 'password') return this.setState({ toggleConfirmNewPassword: 'text' })
      this.setState({ toggleConfirmNewPassword: 'password' })
    }
  }

  async componentDidMount() {
    let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

    if (data.success) {
      this.setState({ data: data.user, phoneNumber: data.user.phoneNumber })
    }
  }

  async handleNumber(event) {
    event.preventDefault()

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/change-number`, { phoneNumber: this.state.phoneNumber, token: localStorage.getItem('token') }, { headers: { 'Content-Type': 'application/json' } });
    
    if (data.success) {
      alert('Phone number have been changed')
    } else {
      alert('Something went wrong')
    }
  }

  async handleChangePassword(event) {
    event.preventDefault()

    console.log(this.state)

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/change-password`, { currentPassword: this.state.currentPassword, newPassword: this.state.newPassword, token: localStorage.getItem('token') }, { headers: { 'Content-Type': 'application/json' } });
    
    if (data.success) {
      alert('password have been changed')
    } else {
      alert('Something went wrong')
    }
  }
  
  render() {
    return (
      <div className='myaccount'>
        <form className="info" onSubmit={this.handleNumber}>
          <h1>Your Profile</h1>
          <div className="mb-2">
            <label>Name</label>
            <input type="text" value={this.state.data.name} disabled={true} />
          </div>

          <div className="mb-2">
            <label>Email</label>
            <input type="email" value={this.state.data.email} disabled={true} />
          </div>

          <div className="mb-2">
            <label>Phone Number</label>
            <input type="text" required value={this.state.phoneNumber} onChange={(event) => this.setState
            ({ phoneNumber: event.target.value })} />
          </div>

          <div className="mb-2">
            <label>Business Type</label>
            <input type="text" value={this.state.data.businessType} disabled={true} />
          </div>

          <button className="button my-3" type='submit'>Submit</button>
        </form>

        <form className='info' onSubmit={this.handleChangePassword}>
          <h1>Change Password</h1>
          <div className="mb-2">
            <label>Current Password</label>
            <div className="d-flex align-items-center">
              <input type={this.state.toggleCurrentPassword} required value={this.state.currentPassword} onChange={(event) => this.setState({ currentPassword: event.target.value })} /><span onClick={() => this.togglePassword('currentPassword')}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
            </div>
          </div>

          <div className="mb-2">
            <label>New Password</label>
            <div className="d-flex align-items-center">
              <input type={this.state.toggleNewPassword} required value={this.state.newPassword} onChange={(event) => this.setState({ newPassword: event.target.value })} /><span onClick={() => this.togglePassword('newPassword')}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
            </div>
          </div>

          <div className="mb-2">
            <label>Confirm New Password</label>
            <div className="d-flex align-items-center">
              <input type={this.state.toggleConfirmNewPassword} required value={this.state.confirmNewPassword} onChange={(event) => this.setState({ confirmNewPassword: event.target.value })} />
              <span onClick={() => this.togglePassword('confirmNewPassword')}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
            </div>
          </div>

          <button className="button my-3" type='submit'>Submit</button>
        </form>
      </div>
    )
  }
}

export default MyAccount