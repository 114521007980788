import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './service-card.scss'

export class ServiceCard extends Component {
  render() {
    return (
      <div className='service-card'>
        <h1>{this.props.serviceName}</h1>
        <p>{this.props.serviceDesc}</p>
        <Link to={`${this.props.serviceLink}`} className='getStarted'>Get Started</Link>
      </div>
    )
  }
}

export default ServiceCard