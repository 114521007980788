import axios from 'axios'
import React, { Component } from 'react'
import { SpinnerCircular } from 'spinners-react'

import Error from '../../components/error/error'
import Success from '../../components/error/success'

import Milestone from '../../components/dashboard/Milestone.json'

import './trackmap.scss'

export class IRCRenewal extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      data: '',
      businessType: '',
      companyName: '',
      importLimit: '5,00,000',
      ircCertificate: '',
      ircCertificateNumber: '',
      error: null,
      success: null,
      transIDStat: true,
      transID: ''
    }
    
    this.handleIRCRenewal = this.handleIRCRenewal.bind(this)
    this.handleTransID = this.handleTransID.bind(this)
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business/get`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      console.log(localStorage.getItem('token') )
      if (data.success) {
        if (data.data.ircRenewal?.transactionID === '' || !data.data.ircRenewal?.transactionID) this.setState({ transIDStat: true })
        else this.setState({ transIDStat: false })
        this.setState({ status: data.data.ircRenewal?.status, businessType: data.data.businessType, step: data.data.ircRenewal?.step, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault()

    this.setState({ transIDStat: false })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`, {
      token: localStorage.getItem('token'),
      service: 'ircRenewal',
      transID: this.state.transID
    })

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
    } else {
      this.setState({ error: 'Something went wrong. Please try again.' })
    }
  }

  async handleIRCRenewal(event) {
    event.preventDefault();
    
    this.setState({ loading: true })

    const formData = new FormData();

    formData.append('companyName', this.state.companyName);
    formData.append('ircCertificate', this.state.ircCertificate);
    formData.append('ircCertificateNumber', this.state.ircCertificateNumber)
    formData.append('importLimit', this.state.importLimit);
    formData.append('token', localStorage.getItem('token'))

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/irc-renewal`, formData)

    console.log(data);
    if (data.success) {
      this.setState({ status: true })
      this.setState({ success: 'IRC Renewal documents submitted'})
      this.setState({ step: 0 })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
      window.location.href = '/service/irc-renewal'
    }
  }

  render() {
    return (
      <div className='trade-license'>
        { (this.state.error) ? <Error message={this.state.error}></Error> : null }
        { (this.state.success) ? <Success message={this.state.success}></Success> : null }
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className="container">
            <h1 className='serviceHeading'>IRC Renewal</h1>
            { (this.state.status) ? (
              <div className="track-map">
                {Milestone['ircRenewal'].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div className={'vertical-divider ' + (index === 9 ? 'last' : '')}></div>
                        </div>
                      </div>
                      <div className={'invite-subtitle ' + (index <= this.state.step ? 'active ' : '')}>
                        <h5>{ms}</h5>
                        {(this.state.step === 3 && index === 3 && this.state.transIDStat) ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input placeholder='Transaction ID' type='text' onChange={(event) => this.setState({ transID: event.target.value })}></input>
                              <button className='button' type='submit'>Submit</button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleIRCRenewal}>

                  <label>{(this.state.businessType === 'Sole proprietorship') ? 'Proprietorship Name*' : (this.state.businessType === 'Partnership') ? 'Partnership firm name*' : (this.state.businessType === 'Private LTd') ? 'Company name*' : (this.state.businessType === 'One Person Company') ? 'One Person Company name*' : 'Company name*'}</label>
                  <input type="text" required onChange={(event) => this.setState({ companyName: event.target.value })} />


                  <label>Import Limit*</label>
                  <select defaultValue={this.state.importLimit} required onChange={(event) => this.setState({ importLimit: event.target.value })}>
                    <option value='5,00,000'>5,00,000</option>
                    <option value='5,00,001-25,00,000'>5,00,001-25,00,000</option>
                    <option value='25,00,001-50,00,000'>25,00,001-50,00,000</option>
                    <option value='50,00,001--1,00,00,000'>50,00,001--1,00,00,000</option>
                    <option value='1,00,00,001-5,00,00,000'>1,00,00,001-5,00,00,000</option>
                    <option value='5,00,00,001-20,00,00,000'>5,00,00,001-20,00,00,000</option>
                    <option value='20,00,00,001-50,00,00,000'>20,00,00,001-50,00,00,000</option>
                    <option value='50,00,00,001-100,00,00,000'>50,00,00,001-100,00,00,000</option>
                  </select>

                  <label>IRC Certificate Number</label>
                  <input type="text" onChange={(event) => this.setState({ ircCertificateNumber: event.target.value })} />

                  <label>IRC Certificate*</label>
                  <input type="file" required onChange={(event) => this.setState({ ircCertificate: event.target.files[0] })} />

                  <button className="button" type='submit'>Submit</button>
                </form>
              </div>
            ) }
          </div>
        )}
      </div>
    )
  }
}

export default IRCRenewal