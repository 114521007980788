import React, { Component } from 'react'
import axios from 'axios'
import { SpinnerCircular } from 'spinners-react'

import BusinessForm from './business-form/business-form'

import Error from '../../components/error/error'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

export default class Register extends Component {
  constructor() {
    super();
    
    this.state = {
      loading: false,
      name: '',
      email: '',
      businessType: 'Sole proprietorship',
      step1: true,
      step2: false,
      step3: false,
      error: null,
      password: '',
      confirmPassword: '',
      code: '',
      resendOTP: false,
      phoneNumber: '',
      passwordType: 'password',
      confirmPasswordType: 'password'
    }

    this.register = this.register.bind(this)
    this.verify = this.verify.bind(this)
    this.resendOTP = this.resendOTP.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleConfirmPassword = this.toggleConfirmPassword.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem('businessForm') === 'true') window.location.href = '/'
    if (localStorage.getItem('user-id')) this.setState({ step1: false, step2: true, step3: false })
    else if (localStorage.getItem('token')) this.setState({ step1: false, step2: false, step3: true })
  }

  async register(event) {
    event.preventDefault();

    this.setState({ error: null, loading: true })

    console.log(this.state.businessType)
    
    if (this.state.name === '') return this.setState({ error: 'Please provide your name'})
    if (this.state.email === '') return this.setState({ error: 'Please provide a valid email' })
    if (this.state.phoneNumber === '') return this.setState({ error: 'Please provide a valid phone number' })
    if (this.state.businessType === '') return this.setState({ error: 'Please provide your business type' })
    if (this.state.password === '') return this.setState({ error: 'Please provide a valid password' })
    if (this.state.password !== this.state.confirmPassword) return this.setState({ error: 'Please provide your business type' })

    let config = {
      headers: {
        'content-type': 'application/json'
      }
    }

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/register`, {
        name: this.state.name,
        email: this.state.email,
        businessType: this.state.businessType,
        password: this.state.password,
        phoneNumber: this.state.phoneNumber
      }, config);
  
      if (data.success) {
        localStorage.setItem('user-id', data.id)
        this.setState({ step1: false, step2: true, loading: false })
      }
    } catch (error) {
      let err = JSON.parse(error.request.response).error;
      this.setState({ error: err, loading: false })
    }
  }

  async verify(event) {
    event.preventDefault();

    this.setState({ loading: true })

    if (this.state.code === '') return this.setState({ error: 'Please provide the verification code', loading: false })

    let id = localStorage.getItem('user-id')
    let config = {
      headers: {
        'content-type': 'application/json'
      }
    }

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/verify`, {
        code: this.state.code,
        id
      }, config);

      if (data.success) {
        localStorage.removeItem('user-id');
        localStorage.setItem('token', data.token);
        localStorage.setItem('business', 'true')
        this.setState({ step1: false, step2: false, step3: true, loading: false })
      }
    } catch (error) {
      let err = JSON.parse(error.request.response).error;
      this.setState({ error: err, loading: false })
      setTimeout(() => {
        this.setState({ error: false })
      }, 5000)
    }
  }

  async resendOTP(event) {
    event.preventDefault()

    this.setState({ loading: true })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verify`, { id: localStorage.getItem('user-id') }, { headers: { 'Content-Type': 'application/json' } })

    if (data.success) {
      alert('Verification code sent again please check')
      this.setState({ resendOTP: true, loading: false })
      setTimeout(() => this.setState({ resendOTP: false }), 60000)
    } else {
      this.setState({ loading: false })
      alert('Something went wrong')
    }
  }

  toggle() {
    if (this.state.passwordType === 'password') return this.setState({ passwordType: 'text' })
    this.setState({ passwordType: 'password' })
  }

  toggleConfirmPassword() {
    if (this.state.confirmPasswordType === 'password') return this.setState({ confirmPasswordType: 'text' })
    this.setState({ confirmPasswordType: 'password' })
  }

  render() {
    return (
      <div className="auth reg">
        <div className="container pt-5">
          <a href="https://legalxsuite.com" style={{ textDecoration: 'none', color: 'var(--primary)'}}>&larr; Home</a>
        </div>
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className='register'>
            {(this.state.loading)}
            { (this.state.error) ? <Error message={this.state.error}></Error> : null }
            { (this.state.step1) ? (
              <div className="form">
                <div className="container">
                  <form onSubmit={this.register}>
                    <label>Name</label>
                    <input type="text" required className='name' value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />

                    <label>Email</label>
                    <input type="email" required className='email' value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} />

                    <label>Phone number</label>
                    <input type="text" required className='email' value={this.state.phoneNumber} onChange={(event) => this.setState({ phoneNumber: event.target.value })} />

                    <label>Password</label>
                    <div className='d-flex align-items-center'>
                      <input type={this.state.passwordType} required className='password' value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })}></input><span onClick={this.toggle}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
                    </div>

                    <label>Confirm Password</label>
                    <div className='d-flex align-items-center'>
                      <input type={this.state.confirmPasswordType} required className='confirmPasword' value={this.state.confirmPassword} onChange={(event) => this.setState({ confirmPassword: event.target.value })} /><span onClick={this.toggleConfirmPassword}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
                      <span></span>
                    </div>
                  
                    <label>Select the type of business</label>
                    <select defaultValue={this.state.businessType} required onChange={(event) => this.setState({ businessType: event.target.value })}>
                      <option value="Sole proprietorship">Sole proprietorship</option>
                      <option value="One Person Company">One Person Company</option>
                      <option value="Private LTD">Private LTD</option>
                      <option value="Partnership">Partnership</option>
                      <option value="Association">Association</option>
                      <option value="Foreign Company">Foreign Company</option>
                    </select>

                    <button className='button mt-3' type='submit'>Submit</button>
                  </form>
                </div>
              </div>
            ) : null }
            { (this.state.step2) ? (
              <div className="verification">
                <div className="container">
                  <div className="form">
                    <form>
                      <label>Enter the verification code sent on your email</label>
                      <input type="text" required value={this.state.code} onChange={(event) => this.setState({ code: event.target.value })}/>

                      <button className="button my-3" onClick={(event) => this.verify(event)}>Submit</button>
                      <button className='button' onClick={(event) => this.resendOTP(event)} disabled={this.state.resendOTP}>Resend OTP</button>
                    </form>
                  </div>
                </div>
              </div>
            ) : null }

            { (this.state.step3) ? <BusinessForm></BusinessForm> : null }
          </div>
        )}
      </div>
    )
  }
}
