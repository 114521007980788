import axios from "axios";
import React, { Component } from "react";
import { SpinnerCircular } from "spinners-react";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import Milestone from "../../components/dashboard/Milestone.json";

import "./trackmap.scss";

export class CompanyFormation extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      data: "",
      businessType: "",
      authorizedCapital: "10,00,000 - 50,00,000",
      companyName: "",
      businessAddress: "",
      businessActivity: "",
      noOfShareholders: "",
      formxii: "",
      error: null,
      success: null,
      transIDStat: true,
      transID: "",
    };

    this.handleCompanyFormation = this.handleCompanyFormation.bind(this);
    this.handleTransID = this.handleTransID.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(localStorage.getItem("token"));
      if (data.success) {
        if (
          data.data.companyFormation?.transactionID === "" ||
          !data.data.companyFormation?.transactionID
        )
          this.setState({ transIDStat: true });
        else this.setState({ transIDStat: false });
        this.setState({
          status: data.data.companyFormation?.status,
          businessType: data.data.businessType,
          step: data.data.companyFormation?.step,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault();

    this.setState({ transIDStat: false });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`,
      {
        token: localStorage.getItem("token"),
        service: "companyFormation",
        transID: this.state.transID,
      }
    );

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
    } else {
      this.setState({ error: "Something went wrong. Please try again." });
    }
  }

  async handleCompanyFormation(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("authorizedCapital", this.state.authorizedCapital);
    formData.append("companyName", this.state.companyName);
    formData.append("businessAddress", this.state.businessAddress);
    formData.append("token", localStorage.getItem("token"));

    if (this.state.businessActivity !== "")
      formData.append("businessActivity", this.state.businessActivity);
    if (this.state.noOfShareholders !== "")
      formData.append("noOfShareholders", this.state.noOfShareholders);
    if (this.state.formxii !== "")
      formData.append("formxii", this.state.formxii);

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/company-formation`,
      formData
    );

    console.log(data);
    if (data.success) {
      this.setState({ status: true });
      this.setState({ success: "Company Formation documents submitted" });
      this.setState({ step: 0 });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/service/company-formation";
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">Company Formation</h1>
            {this.state.status ? (
              <div className="track-map">
                {Milestone["companyFormation"].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div
                            className={
                              "vertical-divider " + (index === 14 ? "last" : "")
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className={
                          "invite-subtitle " +
                          (index <= this.state.step ? "active " : "")
                        }
                      >
                        <h5>{ms}</h5>
                        {this.state.step === 3 &&
                        index === 3 &&
                        this.state.transIDStat ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input
                                placeholder="Transaction ID"
                                type="text"
                                onChange={(event) =>
                                  this.setState({ transID: event.target.value })
                                }
                              ></input>
                              <button className="button" type="submit">
                                Submit
                              </button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleCompanyFormation}>
                  <label>Authorized Capital*</label>
                  <select
                    value={this.state.authorizedCapital}
                    onChange={(event) =>
                      this.setState({ authorizedCapital: event.target.value })
                    }
                  >
                    <option value="10,00,000 - 50,00,000">
                      10,00,000 - 50,00,000
                    </option>
                    <option value="50,00,000 - 1,00,00,000">
                      50,00,000 - 1,00,00,000
                    </option>
                    <option value="1,00,00,000 - 3,00,00,000">
                      1,00,00,000 - 3,00,00,000
                    </option>
                    <option value="3,00,00,000 - Above">
                      3,00,00,000 - Above
                    </option>
                  </select>

                  <label>Proposed Company Name*</label>
                  <input
                    type="text"
                    required
                    onChange={(event) =>
                      this.setState({ companyName: event.target.value })
                    }
                  />

                  <label>Business address of the company</label>
                  <input
                    type="text"
                    onChange={(event) =>
                      this.setState({ businessAddress: event.target.value })
                    }
                  />

                  {this.state.businessType === "Private LTD" ? (
                    <div className="businesss">
                      <label>Number of shareholders*</label>
                      <input
                        type="text"
                        required
                        onChange={(event) =>
                          this.setState({
                            noOfShareholders: event.target.value,
                          })
                        }
                      />
                    </div>
                  ) : null}

                  {this.state.businessType === "One Person Company" ||
                  this.state.businessType === "Private LTD" ? (
                    <div className="businesss">
                      <label>
                        Describe the business activity of the business*
                      </label>
                      <input
                        type="text"
                        required
                        onChange={(event) =>
                          this.setState({ businessAddress: event.target.value })
                        }
                      />
                    </div>
                  ) : null}

                  {this.state.businessType === "Sole proprietorship" ||
                  this.state.businessType === "Partnership" ? (
                    <div className="form">
                      <label>Form XII</label>
                      <input
                        type="file"
                        required
                        onChange={(event) =>
                          this.setState({ formxii: event.target.files[0] })
                        }
                      />
                    </div>
                  ) : null}

                  <button className="button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default CompanyFormation;
