import { Component, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import axios from "axios";

import "./App.scss";

import logo from "./assets/logo_white.png";

import Protected from "./controllers/Protected";

import Login from "./screens/auth/login";
import Register from "./screens/auth/register";
import Logout from "./screens/auth/logout";
import ForgetPassword from "./screens/auth/forgetPassword";
import ResetPassword from "./screens/auth/resetPassword";

import TrackMap from "./components/dashboard/TrackMap";

import Dashboard from "./screens/dashboard";
import MyAccount from "./screens/myAccount";

import PaymentGateway from "./screens/PaymentGateway";

import TradeLicense from "./screens/services/tradeLicense";
import TradeLicenseRenewal from "./screens/services/tradeLicenseRenewal";
import TradeLicenseCorrection from "./screens/services/tradeLicenseCorrection";
import BIN from "./screens/services/bin";
import DBID from "./screens/services/dbid";
import IRC from "./screens/services/irc";
import ERC from "./screens/services/erc";
import IRCRenewal from "./screens/services/ircRenewal";
import ERCRenewal from "./screens/services/ercRenewal";
import MonthlyVat from "./screens/services/vatFiling";
import CompanyFormation from "./screens/services/companyFormation";
import TIN from "./screens/services/tin";
import LimitedPartnership from "./screens/services/limitedPartnership";
import Copyright from "./screens/services/copyright";
import Trademark from "./screens/services/trademark";
import TaxFiling from "./screens/services/taxFiling";
import AuditFiling from "./screens/services/auditFiling";
import BusinessDocuments from "./screens/BusinessDocuments";

import account from "./assets/myAccount.png";
import services from "./assets/services.png";
import file from "./assets/file.png";
import logout from "./assets/logout.png";
import payment from "./assets/payment.png";

import MenuRoundedIcon from "./components/dashboard/MenuRoundedIcon";

const DashSidebar = ({ businessType }) => {
  const { collapseSidebar } = useProSidebar();

  const [collapse, setCollapse] = useState();

  useEffect(() => {
    if (window.innerWidth <= 768) setCollapse(true);
    else setCollapse(false);
  }, []);

  return (
    <Sidebar defaultCollapsed={collapse}>
      <Menu>
        <MenuItem
          className="menu1"
          icon={<MenuRoundedIcon></MenuRoundedIcon>}
          onClick={() => {
            collapseSidebar();
          }}
        >
          <div className="branding">
            <img src={logo} alt="logo" width="120px" />
          </div>
        </MenuItem>
        <MenuItem
          component={<Link to="/"></Link>}
          icon={<img src={services} width="20px" alt="dashboard" />}
        >
          Dashboard
        </MenuItem>
        <SubMenu
          label="Services"
          icon={<img src={file} width="20px" alt="services" />}
        >
          <MenuItem component={<Link to="/service/trade-license"></Link>}>
            Trade License
          </MenuItem>
          <MenuItem
            component={<Link to="/service/trade-license-correction"></Link>}
          >
            Trade License Correction
          </MenuItem>
          <MenuItem
            component={<Link to="/service/trade-license-renewal"></Link>}
          >
            Trade License Renewal
          </MenuItem>
          <MenuItem component={<Link to="/service/bin"></Link>}>BIN</MenuItem>
          <MenuItem component={<Link to="/service/tin"></Link>}>TIN</MenuItem>
          <MenuItem component={<Link to="/service/dbid"></Link>}>DBID</MenuItem>
          <MenuItem component={<Link to="/service/irc"></Link>}>IRC</MenuItem>
          <MenuItem component={<Link to="/service/erc"></Link>}>ERC</MenuItem>
          <MenuItem component={<Link to="/service/erc-renewal"></Link>}>
            ERC Renewal
          </MenuItem>
          <MenuItem component={<Link to="/service/irc-renewal"></Link>}>
            IRC Renewal
          </MenuItem>
          <MenuItem component={<Link to="/service/copyright"></Link>}>
            Copyright
          </MenuItem>
          <MenuItem component={<Link to="/service/trademark"></Link>}>
            Trademark
          </MenuItem>
          <MenuItem component={<Link to="/service/tax-filing"></Link>}>
            Tax Filing
          </MenuItem>
          <MenuItem component={<Link to="/service/vat-filing"></Link>}>
            Monthly Vat FIling
          </MenuItem>
          {businessType !== "Sole proprietorship" ? (
            <MenuItem component={<Link to="/service/audit-filing"></Link>}>
              Audit Filing
            </MenuItem>
          ) : null}
          {businessType === "Private LTD" ||
          businessType === "One Person Company" ? (
            <MenuItem component={<Link to="/service/company-formation"></Link>}>
              Company Formation
            </MenuItem>
          ) : null}
          {businessType === "Private LTD" ||
          businessType === "One Person Company" ? (
            <MenuItem component={<Link to="/service/audit-filing"></Link>}>
              Audit Filing
            </MenuItem>
          ) : null}
          {businessType === "Partnership" ? (
            <MenuItem
              component={<Link to="/service/registered-partnership"></Link>}
            >
              Registered Partnership
            </MenuItem>
          ) : null}
        </SubMenu>
        <MenuItem
          icon={<img src={file} width="20px" alt="account" />}
          component={<Link to="/business-documents"></Link>}
        >
          Business Documents
        </MenuItem>
        <MenuItem
          icon={<img src={account} width="20px" alt="account" />}
          component={<Link to="/my-account"></Link>}
        >
          My Account
        </MenuItem>
        <MenuItem
          icon={<img src={payment} width="20px" alt="payment" />}
          component={<Link to="/payment-gateway"></Link>}
        >
          Request for Payment Gateway
        </MenuItem>
        <MenuItem
          icon={<img src={logout} width="20px" alt="logout" />}
          component={<Link to="/logout"></Link>}
        >
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      collapse: true,
      businessType: "",
    };
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.success) {
        this.setState({ businessType: data.data.businessType, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          {window.location.pathname === "/login" ||
          window.location.pathname === "/register" ||
          window.location.pathname === "/forgot-password" ||
          window.location.pathname.startsWith("/reset-password") ? null : (
            <div className="dashboard">
              <div className="sidebar">
                <DashSidebar businessType={this.state.businessType} />
              </div>
            </div>
          )}

          <div className="contents">
            <div
              className="routes"
              style={
                window.location.pathname === "/login" ||
                window.location.pathname === "/register"
                  ? { padding: "0px" }
                  : { padding: "25px" }
              }
            >
              <Routes>
                <Route exact path="/" element={Protected(Dashboard)}></Route>

                <Route exact path="/login" element={<Login />}></Route>
                <Route exact path="/Register" element={<Register />}></Route>
                <Route exact path="/logout" element={<Logout />}></Route>
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgetPassword />}
                ></Route>
                <Route
                  exact
                  path="/reset-password/:resetToken"
                  element={<ResetPassword />}
                ></Route>

                <Route
                  exact
                  path="/service/trade-license"
                  element={Protected(TradeLicense)}
                ></Route>
                <Route
                  exact
                  path="/service/trade-license-renewal"
                  element={Protected(TradeLicenseRenewal)}
                ></Route>
                <Route
                  exact
                  path="/service/trade-license-correction"
                  element={Protected(TradeLicenseCorrection)}
                ></Route>
                <Route
                  exact
                  path="/service/bin"
                  element={Protected(BIN)}
                ></Route>
                <Route
                  exact
                  path="/service/dbid"
                  element={Protected(DBID)}
                ></Route>
                <Route
                  exact
                  path="/service/irc"
                  element={Protected(IRC)}
                ></Route>
                <Route
                  exact
                  path="/service/erc"
                  element={Protected(ERC)}
                ></Route>
                <Route
                  exact
                  path="/service/erc-renewal"
                  element={Protected(ERCRenewal)}
                ></Route>
                <Route
                  exact
                  path="/service/irc-renewal"
                  element={Protected(IRCRenewal)}
                ></Route>
                <Route
                  exact
                  path="/service/vat-filing"
                  element={Protected(MonthlyVat)}
                ></Route>
                <Route
                  exact
                  path="/service/company-formation"
                  element={Protected(CompanyFormation)}
                ></Route>
                <Route
                  exact
                  path="/service/tin"
                  element={Protected(TIN)}
                ></Route>
                <Route
                  exact
                  path="/service/registered-partnership"
                  element={Protected(LimitedPartnership)}
                ></Route>
                <Route
                  exact
                  path="/service/copyright"
                  element={Protected(Copyright)}
                ></Route>
                <Route
                  exact
                  path="/service/trademark"
                  element={Protected(Trademark)}
                ></Route>
                <Route
                  exact
                  path="/service/tax-filing"
                  element={Protected(TaxFiling)}
                ></Route>
                <Route
                  exact
                  path="/service/audit-filing"
                  element={Protected(AuditFiling)}
                ></Route>

                <Route
                  exact
                  path="/my-account"
                  element={Protected(MyAccount)}
                ></Route>
                <Route
                  exact
                  path="/payment-gateway"
                  element={Protected(PaymentGateway)}
                ></Route>
                <Route
                  exact
                  path="/business-documents"
                  element={Protected(BusinessDocuments)}
                ></Route>
              </Routes>
            </div>

            {window.location.pathname === "/login" ||
            window.location.pathname === "/register" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname.startsWith("/reset-password") ? null : (
              <div className="track-mapper">
                <TrackMap />
              </div>
            )}
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
