import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import Milestone from "../../components/dashboard/Milestone.json";

import "./tradeLicense.scss";
import "./trackmap.scss";

export class LimitedPartnership extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      partnershipType: null,
      type: "incorporationPartnership",
      partnershipAgreementStatus: "No",
      partnershipAgreement: "",
      noOfPartners: "",
      naturePartnership: "",
      companyName: "",
      companyAddress: "",
      transIDStat: true,
      transID: "",
    };

    this.handlePartnership = this.handlePartnership.bind(this);
    this.handleTransID = this.handleTransID.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.success) {
        if (
          data.data.limitedPartnership?.transactionID === "" ||
          !data.data.limitedPartnership?.transactionID
        )
          this.setState({ transIDStat: true });
        else this.setState({ transIDStat: false });
        this.setState({
          status: data.data.limitedPartnership?.status,
          partnershipType: data.data.limitedPartnership?.typeOfPartnership,
          businessType: data.data.businessType,
          step: data.data.limitedPartnership?.step,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault();

    this.setState({ transIDStat: false });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`,
      {
        token: localStorage.getItem("token"),
        service: "limitedPartnership",
        transID: this.state.transID,
      }
    );

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
    } else {
      this.setState({ error: "Something went wrong. Please try again." });
    }
  }

  async handlePartnership(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("type", this.state.type);
    formData.append("noOfPartners", this.state.noOfPartners);
    formData.append("naturePartnership", this.state.naturePartnership);
    formData.append("companyName", this.state.companyName);
    formData.append("companyAddress", this.state.companyAddress);
    formData.append("token", localStorage.getItem("token"));

    if (this.state.partnershipAgreement !== "")
      formData.append("partnershipAgreement", this.state.partnershipAgreement);

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/limited-partnership`,
      formData
    );

    if (data.success) {
      this.setState({ success: "Trade license documents submitted" });
      this.setState({ step: 0 });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/service/registered-partnership";
    }
  }

  render() {
    return (
      <div className="limited-patnership">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">Registered Partnership</h1>
            {this.state.status ? (
              <div className="track-map">
                {Milestone[this.state.partnershipType].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div
                            className={
                              "vertical-divider " + (index === 11 ? "last" : "")
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className={
                          "invite-subtitle " +
                          (index <= this.state.step ? "active " : "")
                        }
                      >
                        <h5>{ms}</h5>
                        {this.state.step === 3 &&
                        index === 3 &&
                        this.state.transIDStat ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input
                                placeholder="Transaction ID"
                                type="text"
                                onChange={(event) =>
                                  this.setState({ transID: event.target.value })
                                }
                              ></input>
                              <button className="button" type="submit">
                                Submit
                              </button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handlePartnership}>
                  <label>Type of partnership*</label>
                  <select
                    value={this.state.type}
                    required
                    onChange={(event) =>
                      this.setState({ type: event.target.value })
                    }
                  >
                    <option value="incorporationPartnership">
                      Incorporation
                    </option>
                    <option value="unregisteredPartnership">
                      Unregistered
                    </option>
                  </select>

                  <label>Do you have a partnership agreement?*</label>
                  <select
                    defaultValue={this.state.partnershipAgreementStatus}
                    required
                    onChange={(event) =>
                      this.setState({
                        partnershipAgreementStatus: event.target.value,
                      })
                    }
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>

                  {this.state.partnershipAgreementStatus === "Yes" ? (
                    <div className="partnership">
                      <label>Partnership Agreement</label>
                      <input
                        type="file"
                        required
                        onChange={(event) =>
                          this.setState({
                            partnershipAgreement: event.target.files[0],
                          })
                        }
                      />
                    </div>
                  ) : null}

                  <label>Number of partners*</label>
                  <input
                    type="text"
                    required
                    onChange={(event) =>
                      this.setState({ noOfPartners: event.target.value })
                    }
                  ></input>

                  <label>Nature of the partnership business*</label>
                  <input
                    type="text"
                    required
                    onChange={(event) =>
                      this.setState({ naturePartnership: event.target.value })
                    }
                  ></input>

                  <label>Partnership firm name*</label>
                  <input
                    type="text"
                    required
                    onChange={(event) =>
                      this.setState({ companyName: event.target.value })
                    }
                  ></input>

                  <label>Partnership firm address</label>
                  <input
                    type="text"
                    onChange={(event) =>
                      this.setState({ companyAddress: event.target.value })
                    }
                  ></input>

                  <button type="submit" className="button">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default LimitedPartnership;
