import React, { Component } from 'react'
import { Field } from 'react-final-form'

export default class Shareholders extends Component {
  render() {
    return (
      <div className='shareholders'>
        <h1>Please select your position in the company</h1>
        <p>We need to know what your position is in the company</p>
        
        <label>Your position</label>
        <Field name='shareholders' component='select'>
          <option />
          <option value="Director and Shareholders">Director and Shareholder</option>
          <option value="Shareholder">Shareholder</option>
          <option value="Director">Director</option>
          <option value="Representative">Representative</option>
          <option value="Owner">Owner</option>
          <option value="Partner">Partner</option>
        </Field>
      </div>
    )
  }
}
