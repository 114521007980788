import axios from 'axios'
import React, { Component } from 'react'
import { default as ReactSelect, components } from 'react-select'
import { SpinnerCircular } from 'spinners-react'

import Error from '../../components/error/error';
import Success from '../../components/error/success';

import Milestone from '../../components/dashboard/Milestone.json'

import './trackmap.scss'

const businessOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          required
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        ></input>
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export class BIN extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: true,
      step: 0,
      data: '',
      error: null,
      success: null,
      businessType: '',
      noEmployees: '',
      projectTurnover: '',
      categoryBusiness: [],
      nid: '',
      tradeLicense: '',
      tin: '',
      bankSolvencyCertificate: '',
      rentalAgreement: '',
      incCertificate: '',
      partnershipAgreement: '',
      partnershipType: '',
      formxii: '',
      categories: [],
      transIDStat: true,
      transID: ''
    }

    this.handleBin = this.handleBin.bind(this)
    this.handleTransID = this.handleTransID.bind(this)
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business/get`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (data.success) {
        if (data.data.bin?.transactionID === '' || !data.data.bin?.transactionID) this.setState({ transIDStat: true })
        else this.setState({ transIDStat: false })
        this.setState({ status: data.data.bin?.status, businessType: data.data.businessType, step: data.data.bin?.step })
        const options = [];
        for (let i = 0; i < data.categoryBusiness.length; i++) {
          options.push({ value: data.categoryBusiness[i], label: data.categoryBusiness[i] })
        }
        this.setState({ categories: options, loading: false });
      }
    } catch (error) {
      console.error(error)
    }
  }

  async handleTransID(event) {
    event.preventDefault()

    this.setState({ transIDStat: false })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`, {
      token: localStorage.getItem('token'),
      service: 'bin',
      transID: this.state.transID
    })

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
    } else {
      this.setState({ error: 'Something went wrong. Please try again.' })
    }
  }

  async handleBin(event) {
    event.preventDefault();
    
    this.setState({ loading: true })

    const formData = new FormData();

    let category = [];

    for (let i = 0; i < this.state.categoryBusiness.length; i++) category.push(this.state.categoryBusiness[i].value);

    formData.append('noEmployees', this.state.noEmployees)
    formData.append('projectTurnover', this.state.projectTurnover)
    formData.append('categoryBusiness', category);
    formData.append('nid', this.state.nid)
    formData.append('tradeLicense', this.state.tradeLicense)
    formData.append('tin', this.state.tin)
    formData.append('bankSolvencyCertificate', this.state.bankSolvencyCertificate)
    formData.append('rentalAgreement', this.state.rentalAgreement)
    formData.append('token', localStorage.getItem('token'))

    if (this.state.noOfPartners !== '') formData.append('noOfPartners', this.state.noOfPartners)
    if (this.state.partnershipType !== '') formData.append('partnershipType', this.state.partnershipType)
    if (this.state.incCertificate !== '') formData.append('incCertificate', this.state.incCertificate)
    if (this.state.partnershipAgreement !== '') formData.append('partnershipAgreement', this.state.partnershipAgreement)
    if (this.state.formxii !== '') formData.append('formxii', this.state.formxii)

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/bin`, formData)

      if (data.success) {
        this.setState({ status: true });
        this.setState({ success: 'BIN documents submitted' })
        this.setState({ step: 0 })
        setTimeout(() => {
          this.setState({ success: null })
        }, 4000)
        window.location.href = '/service/bin'
      }
    } catch (error) {
      this.setState({ error: 'Unexpected error occured. Please try again' })
      setTimeout(() => {
        this.setState({ error: null })
      }, 4000)
    }
  }

  handleChange = (selected) => {
    this.setState({
      categoryBusiness: selected
    });
  };

  render() {
    return (
      <div className='bin'>
        { (this.state.error) ? <Error message={this.state.error}></Error> : null }
        { (this.state.success) ? <Success message={this.state.success}></Success> : null }
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">BIN</h1>
            {(this.state.status) ? (
              <div className="track-map">
                {Milestone['bin'].map((ms, index) => {
                return (
                  <div className="timeline-item" key={index}>
                    <div className="milestone-left">
                      <div className="vertical-divider-container">
                        <div className={'vertical-divider ' + (index === 9 ? 'last' : '')}></div>
                      </div>
                    </div>
                    <div className={'invite-subtitle ' + (index <= this.state.step ? 'active ' : '')}>
                      <h5>{ms}</h5>
                      {(this.state.step === 3 && index === 3 && this.state.transIDStat) ? (
                        <div className="transaction my-2">
                          <form onSubmit={this.handleTransID}>
                            <input placeholder='Transaction ID' type='text' onChange={(event) => this.setState({ transID: event.target.value })}></input>
                            <button className='button' type='submit'>Submit</button>
                          </form>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleBin}>
                  <label>No. of Employees</label>
                  <input type='text' value={this.state.noEmployees} onChange={(event) => this.setState({ noEmployees: event.target.value })}></input>

                  <label>Projected Turnover (Next 12 months)</label>
                  <input type='text' value={this.state.projectTurnover} onChange={(event) => this.setState({ projectTurnover: event.target.value })}></input>

                  <label className='mb-2'>Category of business (according to Trade License)*</label>
                  <ReactSelect
                    options={this.state.categories}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isOptionDisabled={() => this.state.categoryBusiness.length >= 5}
                    components={{
                      businessOption
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={false}
                    value={this.state.categoryBusiness}
                  />

                  <label>NID {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of partners</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of the Managing Director/Chairman' : ''}</label>
                  <input type='file' onChange={(event) => this.setState({ nid: event.target.files[0] })}></input>

                  <label>Trade License {(this.state.businessType === 'Private LTD') ? 'of the company' : (this.state.businessType === 'One Person Company') ? 'of the OPC' : ''} (Current)</label>
                  <input type='file' onChange={(event) => this.setState({ tradeLicense: event.target.files[0] })}></input>

                  <label>TIN {this.state.businessType === 'Private LTD' ? 'of company' : (this.state.businessType === 'Partnership') ? 'of partners' : (this.state.businessType === 'One Person Company') ? 'of the OPC' : ''}</label>
                  <input type='file' onChange={(event) => this.setState({ tin: event.target.files[0] })}></input>

                  <label>Bank Solvency Certificate {this.state.businessType === 'Partnership' ? 'of partnership bank account' : (this.state.businessType === 'Private LTD') ? 'of the company bank account' : (this.state.businessType === 'One Person Company' ? 'of the OPC' : (this.state.businessType === 'Sole proprietorship') ? 'of proprietorship bank account' : '')}</label>
                  <input type='file' onChange={(event) => this.setState({ bankSolvencyCertificate: event.target.files[0] })}></input>

                  <label>Rental Agreement of business premises</label>
                  <input type='file' onChange={(event) => this.setState({ rentalAgreement: event.target.files[0] })}></input>

                  {(this.state.businessType === 'Partnership') ? (
                    <div className="partnership">
                      <label>Select the type of partnership</label><br />
                      <select defaultValue={this.state.partnershipType} onChange={(event) => this.setState({ partnershipType: event.target.value })}>
                        <option value="Registered with RJSC">Registered with RJSC</option>
                        <option value="Not Registered with RJSC">Not Registered with RJSC</option>
                      </select><br />

                      {(this.state.partnershipType === 'Limited Partnership') ? (
                        <div className="inc">
                          <label>Incorporation Certificate</label>
                          <input type='file' onChange={(event) => this.setState({ incCertificate: event.target.files[0] })}></input>
                        </div>
                      ) : null}

                      <label>Number of partners</label>
                      <input type='text' onChange={(event) => this.setState({ noOfPartners: event.target.value })}></input>

                      <label>Partnership Agreement</label>
                      <input type='file' onChange={(event) => this.setState({ partnershipAgreement: event.target.files[0] })}></input>
                    </div>
                  ) : null}

                  {(this.state.businessType === 'One Person Company') || (this.state.businessType === 'Private LTD') ? (
                    <div className="partnership">
                      <label>Incorporation Certificate</label>
                      <input type='file' onChange={(event) => this.setState({ incCertificate: event.target.files[0] })}></input>
                    </div>
                  ) : null}

                  {(this.state.businessType === 'Private LTD') ? (
                    <div className="partnership">
                      <label>Form XII</label>
                      <input type='file' onChange={(event) => this.setState({ formxii: event.target.files[0] })}></input>
                    </div>
                  ) : null}

                  <button className="button my-3" type='submit'>Submit</button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default BIN