import React, { Component } from 'react'
import axios from 'axios'
import { Form } from 'react-final-form'
import { SpinnerCircular } from 'spinners-react'

import BusinessSidebar from './sidebar'

import BusinessActivity from './forms/business-activity'
import Description from './forms/description'
import Shareholders from './forms/shareholders'
import CompanyName from './forms/company-name'
import Summary from './forms/summary'

import Error from '../../../components/error/error'

import './business-form.scss'

export default class BusinessForm extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      titles: ['Business Activity', 'Description', 'Position', 'Company name', 'Summary'],
      pageCount: 0,
      userData: '',
      error: null
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('businessForm') === 'true') return window.location.href = '/'

    console.log(localStorage.getItem('businessForm'))

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }

    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, config)

      console.log(data.user)
      if (data.success) this.setState({ userData: data.user })
    } catch (error) {
      console.log(error);
    }
  }

  async submit(formData) {

    console.log(formData)
    
    this.setState({ error: null, loading: true })

    let config = {
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/create`, {
        industry: formData.industry,
        address: formData.address,
        shareholders: formData.shareholders,
        companyName: formData.companyName
      }, config)

      if (data.success) {
        localStorage.setItem('businessForm', 'true')
        return window.location.href = '/'
      }
    } catch (error) {
      let err = JSON.parse(error.request.response).error;
      this.setState({ error: err, loading: false })
      console.log(error);
    }
  }

  render() {
    return (
      <div className='business-form'>
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className="bsform">
            {(this.state.error) ? <Error message={this.state.error}></Error> : null}
            <div className="row">
              <div className="col-md-4">
                <div className="bssidebar">
                  <BusinessSidebar active={this.state.titles[this.state.pageCount]}></BusinessSidebar>
                </div>
                <div className="bstitle">{this.state.titles[this.state.pageCount]}</div>
              </div>
              <div className="col-md-8">
                <div className="form">
                  <Form onSubmit={(formData) => this.submit(formData)}>
                    {({ handleSubmit, values }) => (
                      <div className="form-container">
                        <form onSubmit={handleSubmit}>
                          { (this.state.pageCount === 0) ? <BusinessActivity name={this.state.userData.name} />
                          : (this.state.pageCount === 1) ? <Description />
                          : (this.state.pageCount === 2) ? <Shareholders type={this.state.userData.businessType} />
                          : (this.state.pageCount === 3) ? <CompanyName /> 
                          : (this.state.pageCount === 4) ? <Summary data={values}/> : null }

                          { (this.state.pageCount === 4) ? <button type='submit'  className='button'>Submit</button> : null }
                        </form>
                        
                        { (this.state.pageCount !== 4) ? 
                          <button className='button next' onClick={() => this.setState({ pageCount: this.state.pageCount + 1 })}>Next</button>
                        : null }

                        { (this.state.pageCount !== 0) ? 
                          <button className='button previous' onClick={() => this.setState({ pageCount: this.state.pageCount - 1 })}>Previous</button> 
                        : null }
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className="sidebar">
        </div> */}
        

        
      </div>
    )
  }
}
