import axios from 'axios'
import React, { Component } from 'react'
import { default as ReactSelect, components } from 'react-select'
import { SpinnerCircular } from 'spinners-react'

import Error from '../../components/error/error'
import Success from '../../components/error/success'

import Milestone from '../../components/dashboard/Milestone.json'

import './tradeLicense.scss'
import './trackmap.scss'

const businessOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          required
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        ></input>
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export class TradeLicense extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      data: '',
      presentAddress: '',
      permanentAddress: '',
      cityCorporation: '',
      businessAddress: '',
      nid: '',
      rentalAgreement: '',
      utilityBill: '',
      picture: '',
      partnershipAgreement: '',
      AOA: '',
      iCertificate: '',
      incNumber: '',
      paidUpCapital: '',
      categoryBusiness: [],
      partnershipType: '',
      noOfPartners: '',
      error: null,
      success: null,
      categories: [],
      transIDStat: true,
      transID: ''
    }
    
    this.handleTradeLicense = this.handleTradeLicense.bind(this)
    this.handleTransID = this.handleTransID.bind(this)
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business/get`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (data.success) {
        if (data.data.tradeLicense?.transactionID === '' || !data.data.tradeLicense?.transactionID) this.setState({ transIDStat: true })
        else this.setState({ transIDStat: false })
        this.setState({ status: data.data.tradeLicense?.status, businessType: data.data.businessType, step: data.data.tradeLicense?.step });
        const options = [];
        for (let i = 0; i < data.categoryBusiness.length; i++) {
          options.push({ value: data.categoryBusiness[i], label: data.categoryBusiness[i] })
        }
        this.setState({ categories: options, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault()

    this.setState({ transIDStat: false })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`, {
      token: localStorage.getItem('token'),
      service: 'tradeLicense',
      transID: this.state.transID
    })

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
    } else {
      this.setState({ error: 'Something went wrong. Please try again.' })
    }
  }

  async handleTradeLicense(event) {
    event.preventDefault();

    this.setState({ loading: true })

    const formData = new FormData();

    let category = [];

    for (let i = 0; i < this.state.categoryBusiness.length; i++) category.push(this.state.categoryBusiness[i].value);

    formData.append('cityCorporation', this.state.cityCorporation);
    formData.append('nid', this.state.nid);
    formData.append('rentalAgreement', this.state.rentalAgreement);
    formData.append('passportPicture', this.state.picture);
    formData.append('utilityBill', this.state.utilityBill);
    formData.append('categoryBusiness', category);
    formData.append('presentAddress', this.state.presentAddress);
    formData.append('permanentAddress', this.state.permanentAddress);
    formData.append('token', localStorage.getItem('token'))

    if (this.state.partnershipType !== '') formData.append('partnershipType', this.state.partnershipType)
    if (this.state.noOfPartners !== '') formData.append('noOfPartners', this.state.noOfPartners)
    if (this.state.AOA !== '') formData.append('AOA', this.state.AOA)
    if (this.state.iCertificate !== '') formData.append('iCertificate', this.state.iCertificate)
    if (this.state.partnershipAgreement !== '') formData.append('partnershipAgreement', this.state.partnershipAgreement)

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/trade-license`, formData)

    if (data.success) {
      this.setState({ status: true })
      this.setState({ success: 'Trade license documents submitted'})
      this.setState({ step: 0 })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
      window.location.href = '/service/trade-license'
    }
  }

  handleChange = (selected) => {
    this.setState({
      categoryBusiness: selected
    });
  };

  render() {
    return (
      <div className='trade-license'>
        { (this.state.error) ? <Error message={this.state.error}></Error> : null }
        { (this.state.success) ? <Success message={this.state.success}></Success> : null }
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (  
          <div className="container">
            <h1 className='serviceHeading'>Trade License</h1>
            { (this.state.status) ? (
              <div className="track-map">
                {Milestone['tradeLicense'].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div className={'vertical-divider ' + (index === 9 ? 'last' : '')}></div>
                        </div>
                      </div>
                      <div className={'invite-subtitle ' + (index <= this.state.step ? 'active ' : '')}>
                        <h5>{ms}</h5>
                        {(this.state.step === 3 && index === 3 && this.state.transIDStat) ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input placeholder='Transaction ID' type='text' onChange={(event) => this.setState({ transID: event.target.value })}></input>
                              <button className='button' type='submit'>Submit</button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleTradeLicense}>
                  <label>Present Address {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of the applicant</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of the Managing Director/Chairman' : ''}</label>
                  <input type='text' onChange={(event) => this.setState({ presentAddress: event.target.value })}></input>

                  <label>Permanent Address {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of the applicant</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of the Managing Director/Chairman' : ''}</label>
                  <input type='text' onChange={(event) => this.setState({ permanentAddress: event.target.value })}></input>

                  <label>Select your city corporation*</label><br />
                  <select defaultValue={this.state.cityCorporation} required onChange={(event) => this.setState({ cityCorporation: event.target.value })}>
                    <option value="Dhaka South City Corporation">Dhaka South City Corporation</option>
                    <option value="Dhaka North City Corporation">Dhaka North City Corporation</option>
                  </select><br />

                  
                  <label className='mb-2'>Category of business {(this.state.businessType === 'Sole proprietorship') ? '*': (this.state.businessType === 'Partnership') ? <span>of the partnership firm*</span> : (this.state.businessType === 'One Person Company') ? '(has to be according to Memorandum of Association)*' : (this.state.businessType === 'Private LTD') ? '(has to be according to Memorandum of Association)*' : ''}</label>
                  <ReactSelect
                    options={this.state.categories}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isOptionDisabled={() => this.state.categoryBusiness.length >= 5}
                    components={{
                      businessOption
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={false}
                    value={this.state.categoryBusiness}
                  />

                  <label>NID {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of the applicant</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of the Managing Director/Chairman' : ''}</label>
                  <input type="file" onChange={(event) => this.setState({ nid: event.target.files[0] })} />

                  <label>Rental Agreement of business premises</label>
                  <input type="file" onChange={(event) => this.setState({ rentalAgreement: event.target.files[0] })} />

                  <label>Utility Bill of business premises</label>
                  <input type="file" onChange={(event) => this.setState({ utilityBill: event.target.files[0] })} />

                  <label>Passport size picture {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of the applicant</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of the Managing Director/Chairman' : ''}</label>
                  <input type="file" onChange={(event) => this.setState({ picture: event.target.files[0] })} />

                  {(this.state.businessType === 'One Person Company' || this.state.businessType === 'Private LTD') ? (
                    <div className="partnership">
                      <label>Paid Up Capital*</label><br></br>
                      <select defaultValue={this.state.paidUpCapital} required onChange={(event) => this.setState({ paidUpCapital: event.target.value })}>
                        <option value="1,00,000">1,00,000</option>
                        <option value="1,00,000-5,00,000 ">1,00,000-5,00,000 </option>
                        <option value="5,00,000-10,00,000">5,00,000-10,00,000</option>
                        <option value="10,00,000-25,00,000">10,00,000-25,00,000</option>
                        <option value="25,00,000-50,00,000">25,00,000-50,00,000</option>
                        <option value="50,00,000-1,00,00,000">50,00,000-1,00,00,000</option>
                        <option value="1,00,00,000-5,00,00,000">1,00,00,000-5,00,00,000</option>
                        <option value="5,00,00,000-Above">5,00,00,000 - Above</option>
                      </select>
                    </div>
                  ) : null}

                  {(this.state.businessType === 'Partnership') ? (
                    <div className="partnership">
                      <label>Select the type of partnership</label><br />
                      <select defaultValue={this.state.partnershipType} onChange={(event) => this.setState({ partnershipType: event.target.value })}>
                        <option value="Registered with RJSC">Registered with RJSC</option>
                        <option value="Not Registered with RJSC">Not Registered with RJSC</option>
                      </select><br />
                    </div>
                  ) : null}

                  {(this.state.businessType === 'One Person Company' || this.state.businessType === 'Private LTD') ? (
                    <div className="partnership">
                      <label>Article of Association & Memorandum of Association</label>
                      <input type="file" onChange={(event) => this.setState({ AOA: event.target.files[0] })} />

                      <label>Incorporation Certificate</label>
                      <input type="file" onChange={(event) => this.setState({ iCertificate: event.target.files[0] })} />
                    </div>
                  ) : null}

                  {(this.state.businessType === 'Partnership' && this.state.partnershipType === 'Limited Partnership') ? (
                    <div className="parntership">
                      <label>Incorporation Certificate</label>
                      <input type="file" onChange={(event) => this.setState({ iCertificate: event.target.files[0] })} />
                    </div>
                  ) : null}

                  {(this.state.businessType === 'Partnership') ? (
                    <div className="partnership">
                      <label>Number of partners</label>
                      <input type="text" onChange={(event) => this.setState({ noOfPartners: event.target.value })}></input>

                      <label>Partnership Agreement</label>
                      <input type="file" onChange={(event) => this.setState({ partnershipAgreement: event.target.files[0] })} />
                    </div>
                  ) : null}

                  <button className="button">Submit</button>
                </form>
              </div>
            ) }
          </div>
        )}
      </div>
    )
  }
}

export default TradeLicense