import { Component } from "react";

import jwt_decode from 'jwt-decode'

export default function Protected(ComposedComponent) {
  class Protected extends Component {
    state = {
      isAuthenticated: true
    }

    componentDidMount() {
      
      try {
        const decoded = jwt_decode(localStorage.getItem('token'));
        if (Date.now() >= decoded.exp * 1000) {
          this.setState({ isAuthenticated: false })
          localStorage.removeItem('token')
        } else {
          this.setState({ isAuthenticated: true })
        } 
      } catch (error) {
        this.setState({ isAuthenticated: false })
        localStorage.removeItem('token')
      }
    }

    render() {
      return this.state.isAuthenticated ? <ComposedComponent {...this.props}></ComposedComponent> : window.location.href = '/login'
    }
  }

  return <Protected></Protected>;
}