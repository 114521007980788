import React, { Component } from 'react'
import axios from 'axios'
import { default as ReactSelect, components } from 'react-select'
import { SpinnerCircular } from 'spinners-react'

import Error from '../../components/error/error'
import Success from '../../components/error/success'

import Milestone from '../../components/dashboard/Milestone.json'

import './trackmap.scss'

const businessOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          required
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        ></input>
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export class TradeLicenseRenewal extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      status: false,
      step: 0,
      data: '',
      businessType: '',
      categoryBusiness: [],
      error: null,
      success: null,
      cityCoorporation: 'Dhaka South City Corporation',
      currentTradeLicense: '',
      nid: '',
      passportPicture: '',
      categories: [],
      transIDStat: true,
      transID: ''
    }

    this.handleTradeLicenseRenewal = this.handleTradeLicenseRenewal.bind(this)
    this.handleTransID = this.handleTransID.bind(this)
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business/get`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (data.success) {
        if (data.data.tradeLicenseRenewal?.transactionID === '' || !data.data.tradeLicenseRenewal?.transactionID) this.setState({ transIDStat: true })
        else this.setState({ transIDStat: false })
        this.setState({ status: data.data.tradeLicenseRenewal?.status, businessType: data.data.businessType, step: data.data.tradeLicenseRenewal?.step });
        const options = [];
        for (let i = 0; i < data.categoryBusiness.length; i++) {
          options.push({ value: data.categoryBusiness[i], label: data.categoryBusiness[i] })
        }
        this.setState({ categories: options, loading: false });
      }
    } catch (error) {
      console.log(error)
    }
  }

  async handleTransID(event) {
    event.preventDefault()

    this.setState({ transIDStat: false })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`, {
      token: localStorage.getItem('token'),
      service: 'tradeLicenseRenewal',
      transID: this.state.transID
    })

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
    } else {
      this.setState({ error: 'Something went wrong. Please try again.' })
    }
  }

  async handleTradeLicenseRenewal(event) {
    event.preventDefault();

    this.setState({ loading: true })

    const formData = new FormData();

    let category = [];

    for (let i = 0; i < this.state.categoryBusiness.length; i++) category.push(this.state.categoryBusiness[i].value);

    formData.append('cityCoorporation', this.state.cityCoorporation);
    formData.append('categoryBusiness', category);
    formData.append('tradeLicenseCurrent', this.state.currentTradeLicense);
    formData.append('nid', this.state.nid);
    formData.append('passportPicture', this.state.passportPicture);
    formData.append('token', localStorage.getItem('token'))

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/trade-license-renewal`, formData)

    console.log(data);
    if (data.success) {
      this.setState({ status: true })
      this.setState({ success: 'Trade license renewal requested'})
      this.setState({ step: 0 })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
      window.location.href = '/service/trade-license-renewal'
    }
  }

  handleChange = (selected) => {
    this.setState({
      categoryBusiness: selected
    });
  };

  render() {
    return (
      <div className='trade-license-renewal'>
        { (this.state.error) ? <Error message={this.state.error}></Error> : null }
        { (this.state.success) ? <Success message={this.state.success}></Success> : null }
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">Trade License Renewal</h1>
            { (this.state.status) ? (
              <div className="track-map">
                {Milestone['tradeLicense'].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div className={'vertical-divider ' + (index === 9 ? 'last' : '')}></div>
                        </div>
                      </div>
                      <div className={'invite-subtitle ' + (index <= this.state.step ? 'active ' : '')}>
                        <h5>{ms}</h5>
                        {(this.state.step === 3 && index === 3 && this.state.transIDStat) ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input placeholder='Transaction ID' type='text' onChange={(event) => this.setState({ transID: event.target.value })}></input>
                              <button className='button' type='submit'>Submit</button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
            </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleTradeLicenseRenewal}>
                  <label className='mb-2'>City Corporation*</label>
                  <select name="correction" defaultValue={this.state.cityCoorporation} required onChange={(event) => this.setState({ cityCoorporation: event.target.value })}>
                    <option value='Dhaka South City Corporation'>Dhaka South City Corporation</option>
                    <option value='Dhaka North City Corporation'>Dhaka North City Corporation</option>
                  </select>

                  <label className='mb-2'>Category of business (According to current trade license)</label>
                  <ReactSelect
                    options={this.state.categories}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isOptionDisabled={() => this.state.categoryBusiness.length >= 5}
                    components={{
                      businessOption
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={false}
                    value={this.state.categoryBusiness}
                  />

                  <label>Trade License (Current)*</label>
                  <input type="file" onChange={(event) => this.setState({ currentTradeLicense: event.target.files[0] })} />

                  <label>NID {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of partner (According to trade license)</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of the Managing Director/Chairman' : ''}</label>
                  <input type="file" onChange={(event) => this.setState({ nid: event.target.files[0] })} />

                  <label>Passport size picture {(this.state.businessType === 'Sole proprietorship') ? <span>of Proprietor</span> : (this.state.businessType === 'Partnership') ? <span>of partner (According to trade license)</span> : (this.state.businessType === 'One Person Company') ? 'of the shareholder' : (this.state.businessType === 'Private LTD') ? 'of Managing Director/Chairman' : ''}</label>
                  <input type="file" onChange={(event) => this.setState({ passportPicture: event.target.files[0] })} />

                  <button className="button" type='submit'>Submit</button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default TradeLicenseRenewal;