import React, { Component } from 'react'
import axios from 'axios'

import Error from '../../components/error/error'
import Success from '../../components/error/success'

import Milestone from '../../components/dashboard/Milestone.json'

import { SpinnerCircular } from 'spinners-react'

import './trackmap.scss'

export class TIN extends Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      status: null,
      step: 0,
      tinType: 'Personal',
      nid: '',
      sourceIncome: 'Service',
      purposeTin: 'Declaring Taxable Income',
      companyName: '',
      companyAddress: '',
      error: null,
      success: null,
      transIDStat: true,
      transID: ''
    }

    this.handleTin = this.handleTin.bind(this)
    this.handleTransID = this.handleTransID.bind(this)
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/business/get`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      console.log(localStorage.getItem('token') )
      if (data.success) {
        if (data.data.tin?.transactionID === '' || !data.data.tin?.transactionID) this.setState({ transIDStat: true })
        else this.setState({ transIDStat: false })
        this.setState({ status: data.data.tin?.status, businessType: data.data.businessType, step: data.data.tin?.step });
        this.setState({ loading: false })
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTransID(event) {
    event.preventDefault()

    this.setState({ transIDStat: false })

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`, {
      token: localStorage.getItem('token'),
      service: 'tin',
      transID: this.state.transID
    })

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` })
      setTimeout(() => {
        this.setState({ success: null })
      }, 4000)
    } else {
      this.setState({ error: 'Something went wrong. Please try again.' })
    }
  }

  async handleTin(event) {
    event.preventDefault()

    this.setState({ loading: true })

    const formData = new FormData();

    formData.append('tinType', this.state.tinType)
    formData.append('nid', this.state.nid)
    formData.append('sourceIncome', this.state.sourceIncome)
    formData.append('purposeTin', this.state.purposeTin)
    formData.append('token', localStorage.getItem('token'))
    
    if (this.state.companyName !== '') formData.append('companyName', this.state.companyName)
    if (this.state.companyAddress !== '') formData.append('companyAddress', this.state.companyAddress)

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/business/tin`, formData)
    
    if (data.success) {
      this.setState({ status: true })
      this.setState({ success: 'TIN documents updated' })
      this.setState({ step: 0 })
      setTimeout(() => {
        this.setState({ success: null })
      })
      window.location.href = '/service/tin'
    }
  }

  render() {
    return (
      <div className='tin'>
        { (this.state.error) ? <Error message={this.state.error}></Error> : null }
        { (this.state.success) ? <Success message={this.state.success}></Success> : null }
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
        <div className="container">
          <h1 className='serviceHeading'>TIN</h1>
          {(this.state.status === true) ? (
            <div className="track-map">
              {Milestone['tin'].map((ms, index) => {
                return (
                  <div className="timeline-item" key={index}>
                    <div className="milestone-left">
                      <div className="vertical-divider-container">
                        <div className={'vertical-divider ' + (index === 9 ? 'last' : '')}></div>
                      </div>
                    </div>
                    <div className={'invite-subtitle ' + (index <= this.state.step ? 'active ' : '')}>
                      <h5>{ms}</h5>
                      {(this.state.step === 3 && index === 3 && this.state.transIDStat) ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input placeholder='Transaction ID' type='text' onChange={(event) => this.setState({ transID: event.target.value })}></input>
                              <button className='button' type='submit'>Submit</button>
                            </form>
                          </div>
                      ) : null}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : null}

          {(this.state.status === false) ? (
            <div className="form">
              <form onSubmit={this.handleTin}>
                <label>Tin Type*</label>
                <select defaultValue={this.state.tinType} required onChange={(event) => this.setState({ tinType: event.target.value })}>
                  <option value='Personal'>Personal</option>
                  <option value='Partnership'>Partnership</option>
                  <option value='Company'>Company</option>
                  <option value='One Person Company'>One Person Company</option>
                </select>

                <label>NID</label>
                <input type="file"  onChange={(event) => this.setState({ nid: event.target.files[0] })} />

                {(this.state.tinType !== 'Personal') ? (
                  <div className="company">
                    <label>Company name</label>
                    <input type="text"  onChange={(event) => this.setState({ companyName: event.target.value })} />

                    <label>Company address</label>
                    <input type="text"  onChange={(event) => this.setState({ companyAddress: event.target.value })} />
                  </div>
                ) : null}

                <label>Source of income*</label>
                <select defaultValue={this.state.sourceIncome} required onChange={(event) => this.setState({ sourceIncome: event.target.value })}>
                  <option value='Service'>Service</option>
                  <option value='Profession'>Profession</option>
                  <option value='Business (Individual/Firm)'>Business (Individual/Firm)</option>
                  <option value='Others'>Others</option>
                </select>

                <label>Purpose of TIN</label>
                <select defaultValue={this.state.purposeTin}  onChange={(event) => this.setState({ purposeTin: event.target.value })}>
                  <option value='Declaring Taxable Income'>Declaring Taxable Income</option>
                  <option value='Obtaining a credit card'>Obtaining a credit card</option>
                  <option value='Others'>Others</option>
                  <option value='Selling land'>Selling land</option>
                </select>

                <button className='button' type='submit'>Submit</button>
              </form>
            </div>
          ) : null}
        </div>
        )}
      </div>
    )
  }
}

export default TIN