import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import ServiceCard from "../components/dashboard/service-card";

import "./dashboard.scss";

export class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      businessType: "",
    };
  }

  async componentDidMount() {
    if (
      localStorage.getItem("businessForm") === "false" ||
      !localStorage.getItem("businessForm")
    )
      window.location.href = "/register";

    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.success) {
        this.setState({ businessType: data.data.businessType, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="services">
        <div className="service-panel">
          <h3>Our Services</h3>
          {this.state.loading ? (
            <div className="spinner">
              <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
            </div>
          ) : (
            <div className="services">
              {this.state.businessType === "Private LTD" ||
              this.state.businessType === "One Person Company" ? (
                <ServiceCard
                  serviceName="Company Formation"
                  serviceDesc="Company incorporation is the process of registering a new company with the relevant authorities, such as the RJSC. The process involves selecting a business structure, choosing a name, preparing documents, and submitting an application."
                  serviceLink="/service/company-formation"
                ></ServiceCard>
              ) : null}

              {this.state.businessType === "Partnership" ? (
                <ServiceCard
                  serviceName="Registered Partnership"
                  serviceDesc="A registered partnership firm is a business structure formed by two or more individuals who come together to conduct a business and register with the Registrar of Joint Stock Companies and Firms in order to enjoy certain legal benefits and protections."
                  serviceLink="/service/registered-partnership"
                ></ServiceCard>
              ) : null}

              <ServiceCard
                serviceName="Trade License"
                serviceDesc="A trade license is a mandatory document that certifies the legal operation of a business and allows it to conduct trade within the jurisdiction of the local government authority in Bangladesh"
                serviceLink="/service/trade-license"
              ></ServiceCard>

              <ServiceCard
                serviceName="Trade License Correction"
                serviceDesc="Trade license correction refers to the process of updating or rectifying any mistakes or inaccuracies in an existing trade license owned by a business."
                serviceLink="/service/trade-license-correction"
              ></ServiceCard>

              <ServiceCard
                serviceName="Trade License Renewal"
                serviceDesc="Trade license renewal is the process of extending the validity of an existing trade license by submitting an application form to the relevant authority with the required documents and fees."
                serviceLink="/service/trade-license-renewal"
              ></ServiceCard>

              <ServiceCard
                serviceName="Business Identification Number (BIN)"
                serviceDesc="A BIN certificate is a required document for businesses operating in Bangladesh, as it serves as a primary registration for tax purposes and allows businesses to conduct various financial transactions"
                serviceLink="/service/bin"
              ></ServiceCard>

              <ServiceCard
                serviceName="Tax Identification Number (TIN)"
                serviceDesc="A Tax Identification Number (TIN) is a 12-digit number assigned to individuals and businesses in Bangladesh for tax purposes. It is issued by the National Board of Revenue and is used to identify taxpayers and track tax payments."
                serviceLink="/service/tin"
              ></ServiceCard>

              <ServiceCard
                serviceName="Digital Business Identification (DBID)"
                serviceDesc="Digital Business Identification (DBID) is a certificate provided by the Government of Bangladesh, which authenticates whether a business is registered to provide services/products and make transactions digitally."
                serviceLink="/service/dbid"
              ></ServiceCard>

              <ServiceCard
                serviceName="Export Registration Certificate (ERC)"
                serviceDesc="The Export Registration Certificate (ERC) is mandatory for individuals and companies exporting goods from Bangladesh. It is issued by the Office of Chief Controller of Imports and Exports (CCI&E) and verifies exporter identity and the nature of goods."
                serviceLink="/service/erc"
              ></ServiceCard>

              <ServiceCard
                serviceName="Import Registration Certificate (IRC)"
                serviceDesc="The Import Registration Certificate (IRC) is mandatory for individuals and companies importing goods into Bangladesh. It is issued by the Office of Chief Controller of Imports and Exports (CCI&E) and verifies the identity of the importer and the nature of the imported goods."
                serviceLink="/service/irc"
              ></ServiceCard>

              <ServiceCard
                serviceName="Export Registration Certificate (ERC) Renewal"
                serviceDesc="ERC renewal is the process of renewing the Export Registration Certificate (ERC) in Bangladesh. The ERC is mandatory for individuals and companies exporting goods and is valid for two years."
                serviceLink="/service/erc-renewal"
              ></ServiceCard>

              <ServiceCard
                serviceName="Import Registration Certificate (IRC) Renewal"
                serviceDesc="IRC renewal is the process of renewing the Import Registration Certificate (IRC) in Bangladesh. The IRC is mandatory for individuals and companies importing goods and is valid for two years."
                serviceLink="/service/irc-renewal"
              ></ServiceCard>

              <ServiceCard
                serviceName="Copyright"
                serviceDesc="Copyright protects original works of authorship, such as literary, artistic, and musical creations. It grants the creator exclusive rights to reproduce, distribute, and display the work, providing legal protection against unauthorized use."
                serviceLink="/service/copyright"
              ></ServiceCard>

              <ServiceCard
                serviceName="Trademark"
                serviceDesc="A trademark is a symbol, word, phrase, or design that identifies and distinguishes the source of goods or services from those of others. It serves as a unique identifier and helps consumers recognize and differentiate products or services in the marketplace."
                serviceLink="/service/trademark"
              ></ServiceCard>

              <ServiceCard
                serviceName="Monthly Vat Filing"
                serviceDesc="Monthly VAT return filing is the process of submitting a monthly VAT return to the National Board of Revenue (NBR). It involves providing details of sales and purchases, VAT collected and paid, and other relevant information."
                serviceLink="/service/vat-filing"
              ></ServiceCard>

              {this.state.businessType === "One Person Company" ||
              this.state.businessType === "Private LTD" ||
              this.state.businessType === "Partnership" ? (
                <ServiceCard
                  serviceName="Audit Filing"
                  serviceDesc="Companies are required to submit their annual return to the Registrar of Joint Stock Companies (RJSC) each calendar year and hold an Annual General Meeting (AGM). The AGM must take place within 18 months of the company's incorporation date, with no more than 15 months between subsequent AGMs."
                  serviceLink="/service/audit-filing"
                ></ServiceCard>
              ) : null}

              <ServiceCard
                serviceName="Tax Filing"
                serviceDesc="A tax return is submitted to the National Board of Revenue (NBR) typically annually, by individuals or entities to report their income, expenses, and other financial information. It details their tax liability, deductions, and credits, and calculates the amount of tax owed to the government or any refund due."
                serviceLink="/service/tax-filing"
              ></ServiceCard>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dashboard;
