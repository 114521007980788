import axios from 'axios'
import React, { Component } from 'react'
import { SpinnerCircular } from 'spinners-react'

import './auth.scss'

import login from '../../assets/login.jpg'

import Error from '../../components/error/error'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

export class Login extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      email: '',
      password: '',
      error: null,
      passwordType: 'password'
    }

    this.handleLogin = this.handleLogin.bind(this);
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    if (this.state.passwordType === 'password') return this.setState({ passwordType: 'text' })
    this.setState({ passwordType: 'password' })
  }

  async handleLogin(event) {
    event.preventDefault();

    this.setState({ loading: true })
    
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
        email: this.state.email,
        password: this.state.password
      }, config);
  
  
      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('businessForm', data.businessForm)
        window.location.href = '/'
      } else {
        console.log(data)
        this.setState({ error: 'Incorrect email or password', loading: false })
      } 
    } catch (error) {
      this.setState({ error: "Account doesn't exist", loading: false })
    }
  }

  render() {
    return (
      <div className="authlog">
        <div className="container pt-5">
          <a href="https://legalxsuite.com" style={{ textDecoration: 'none', color: 'var(--primary)'}}>&larr; Home</a>
        </div>
        {(this.state.loading) ? (
          <div className="spinner"><SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular></div>
        ) : (
          <div className="contain">
            <div className='login'>
              {(this.state.error) ? <Error message={this.state.error}></Error> : null}
              <div className="row">
                <div className="col-md-6">
                  <img src={login} alt="Login" className='img-fluid loginImg'  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div className="formLogin w-100">
                    <h1>Login</h1>
                    <form onSubmit={this.handleLogin}>
                      <input type='email' required value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} placeholder='Email Address'/>


                      <div className="d-flex align-items-center">
                        <input type={this.state.passwordType} required value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} placeholder='Password'/>
                        <span onClick={this.toggle}><FontAwesomeIcon icon={faEye} size='1x' className='eyes'></FontAwesomeIcon></span>
                      </div>

                      <a href="/forgot-password" className='login-link'>Forgot Password</a>

                      <button className="button loginButton" type='submit'>Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Login