import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import { withRouter } from "../../controllers/withRouter";

export class ResetPassword extends Component {
  constructor() {
    super();

    let token = localStorage.getItem("token");
    if (token) window.location.href = "/";

    this.state = {
      password: "",
      confirmPassword: "",
      passwordType: "password",
      confirmPasswordType: "password",
      error: null,
      success: false,
    };

    this.setNewPassword = this.setNewPassword.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleConfirmPassword = this.toggleConfirmPassword.bind(this);
  }

  toggle() {
    if (this.state.passwordType === "password")
      return this.setState({ passwordType: "text" });
    this.setState({ passwordType: "password" });
  }

  toggleConfirmPassword() {
    if (this.state.confirmPasswordType === "password")
      return this.setState({ confirmPasswordType: "text" });
    this.setState({ confirmPasswordType: "password" });
  }

  async setNewPassword(event) {
    event.preventDefault();

    this.setState({ error: null });

    if (this.state.password !== this.state.confirmPassword)
      return this.setState({
        error: "Passwords don't match. Please check again!",
      });

    try {
      let resetToken = this.props.params.resetToken;
      console.log(resetToken);

      let { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password/${resetToken}`,
        { password: this.state.password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (data.success) {
        this.setState({
          success:
            "Password has been reset successfully. Redirecting you to login page...",
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      }
    } catch (error) {
      this.setState({ error: "Password reset link expired. Please try again" });
      console.log(error);
    }
  }

  render() {
    return (
      <div className="resetPassword">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success === false ? (
          <div className="container">
            <h1>Reset your password</h1>

            <form onSubmit={this.setNewPassword}>
              <div className="mb-3">
                <label>Password</label>
                <div className="d-flex align-items-center">
                  <input
                    required
                    type={this.state.passwordType}
                    value={this.state.password}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    id="password"
                    aria-describedby="password"
                  />
                  <span onClick={this.toggle}>
                    <FontAwesomeIcon
                      icon={faEye}
                      size="1x"
                      className="eyes"
                    ></FontAwesomeIcon>
                  </span>
                </div>
              </div>

              <div className="mb-3">
                <label>Confirm Password</label>
                <div className="d-flex align-items-center">
                  <input
                    required
                    type={this.state.confirmPasswordType}
                    value={this.state.confirmPassword}
                    onChange={(event) =>
                      this.setState({ confirmPassword: event.target.value })
                    }
                    id="confirm-password"
                    aria-describedby="confirm-password"
                  />
                  <span onClick={this.toggleConfirmPassword}>
                    <FontAwesomeIcon
                      icon={faEye}
                      size="1x"
                      className="eyes"
                    ></FontAwesomeIcon>
                  </span>
                </div>
              </div>

              <button className="button" type="submit">
                Submit
              </button>
            </form>
          </div>
        ) : (
          <Success message={this.state.success}></Success>
        )}
      </div>
    );
  }
}

export default withRouter(ResetPassword);
