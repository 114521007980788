import React, { Component } from 'react'
import { Field } from 'react-final-form'

export default class Description extends Component {
  render() {
    return (
      <div className='description'>
        <h1>Your business information</h1>
        <p>Let’s clarify some details about your business</p>

        <h4>Office address in Bangladesh</h4>
        <p>Where is your business located? If you don’t have a commercial space, we can help you out with that too!</p>
        <Field name='address'>
          {({ input }) => <input placeholder='Registered office address' type='text' {...input} />}
        </Field>

        {/* <h4>Do you have a website? (optional)</h4>
        <p>A website goes a long way to establish the credibility of your business and can be a requirement for several government documentations</p>
        <Field name='website'>
          {({ input }) => <input placeholder='Your website' type='text' {...input} />}
        </Field> */}
      </div>
    )
  }
}
