import React, { Component } from 'react'

export default class Summary extends Component {
  render() {
    console.log(this.props.data)
    return (
      <div className='summary'>
        <h1 className='mb-4'>Summary</h1>
        <div className="row">
          <div className="col-md-6">
            <h2>Company Name</h2>
            <p>{this.props.data.companyName}</p>
          </div>
          <div className="col-md-6">
            <h2>Business Industry</h2>
            <p>{this.props.data.industry}</p>
          </div>
          <div className="col-md-6">
            <h2>Company Address</h2>
            <p>{this.props.data.address}</p>
          </div>
          <div className="col-md-6">
            <h2>Position</h2>
            <p>{this.props.data.shareholders}</p>
          </div>
        </div>
      </div>
    )
  }
}
