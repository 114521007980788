import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import Milestone from "../../components/dashboard/Milestone.json";

import "./trackmap.scss";

export class Trademark extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      status: false,
      step: 0,
      data: "",
      particulars: "",
      typeOfBusiness: "",
      legalStatusOfBusiness: "Sole proprietor",
      websiteLink: "",
      error: null,
      success: null,
      transIDStat: true,
      transID: "",
    };

    this.handleTrademark = this.handleTrademark.bind(this);
    this.handleTransID = this.handleTransID.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.success) {
        if (
          data.data.trademark?.transactionID === "" ||
          !data.data.trademark?.transactionID
        )
          this.setState({ transIDStat: true });
        else this.setState({ transIDStat: false });
        this.setState({
          status: data.data.trademark?.status,
          businessType: data.data.businessType,
          step: data.data.trademark?.step,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleTrademark(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("particulars", this.state.particulars);
    formData.append("typeOfBusiness", this.state.typeOfBusiness);
    formData.append("legalStatusOfBusiness", this.state.legalStatusOfBusiness);
    formData.append("websiteLink", this.state.websiteLink);
    formData.append("token", localStorage.getItem("token"));

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/trademark`,
      formData
    );

    if (data.success) {
      this.setState({ status: true });
      this.setState({ success: "Trademark documents submitted" });
      this.setState({ step: 0 });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/service/trademark";
    }
  }

  async handleTransID(event) {
    event.preventDefault();

    this.setState({ transIDStat: false });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`,
      {
        token: localStorage.getItem("token"),
        service: "trademark",
        transID: this.state.transID,
      }
    );

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
    } else {
      this.setState({ error: "Something went wrong. Please try again." });
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1>Trademark</h1>
            {this.state.status ? (
              <div className="track-map">
                {Milestone["trademark"].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div
                            className={
                              "vertical-divider " + (index === 11 ? "last" : "")
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className={
                          "invite-subtitle " +
                          (index <= this.state.step ? "active " : "")
                        }
                      >
                        <h5>{ms}</h5>
                        {this.state.step === 3 &&
                        index === 3 &&
                        this.state.transIDStat ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input
                                placeholder="Transaction ID"
                                type="text"
                                onChange={(event) =>
                                  this.setState({ transID: event.target.value })
                                }
                              ></input>
                              <button className="button" type="submit">
                                Submit
                              </button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleTrademark}>
                  <label>
                    Particulars of the mark/logo/device prints or
                    representations*
                  </label>
                  <input
                    type="file"
                    required
                    onChange={(event) =>
                      this.setState({ particulars: event.target.files[0] })
                    }
                  />

                  <label>Type of business</label>
                  <input
                    type="text"
                    value={this.state.typeOfBusiness}
                    onChange={(event) =>
                      this.setState({ typeOfBusiness: event.target.value })
                    }
                    required
                    placeholder="i.e. manufacturers/merchandisers/service providers"
                  />

                  <label>Legal status of business</label>
                  <select
                    value={this.state.legalStatusOfBusiness}
                    onChange={(event) =>
                      this.setState({ typeOfBusiness: event.target.value })
                    }
                  >
                    <option value="Sole Proprietor">Sole Proprietor</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Private Limited Company">
                      Private Limited Company
                    </option>
                    <option value="Other">Other</option>
                  </select>

                  <label>Website Link of business</label>
                  <input
                    type="text"
                    value={this.state.websiteLink}
                    onChange={(event) =>
                      this.setState({ websiteLink: event.target.value })
                    }
                  />

                  <button className="button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Trademark;
