import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Error from "../components/error/error";

import "./services/tradeLicense.scss";

export class BusinessDocuments extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      user: null,
      documents: [],
      error: null,
    };

    this.downloadDocument = this.downloadDocument.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/documents`,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      this.setState({ loading: false, documents: data.files });
    } catch (error) {
      this.setState({
        loading: false,
        error: "Something went wrong! Please try again.",
      });
    }
  }

  async downloadDocument(doc) {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/document/download/${doc}`,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.setAttribute("download", doc);

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      this.setState({ error: "Something went wrong! Please try again." });
      console.error(error);
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading mb-4">Business Documents</h1>
            {this.state.documents.map((doc, i) => (
              <div
                className="d-flex gap-4 align-items-center justify-content-between border border-black p-3 rounded my-3"
                key={i}
              >
                <div>
                  <h3>{doc.name}</h3>
                  <p>Uploaded at: {new Date(doc.timestamp).toLocaleString()}</p>
                </div>
                <div>
                  <button
                    className="button"
                    onClick={() => this.downloadDocument(doc.document)}
                  >
                    Download
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default BusinessDocuments;
