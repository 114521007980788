import React, { Component } from 'react'
import axios from 'axios'

import Error from '../../components/error/error'
import Success from '../../components/error/success'

export class ForgetPassword extends Component {
  constructor() {
    super();

    let token = localStorage.getItem('token')
    if (token) window.location.href = '/'

    this.state = {
      email: '',
      error: null,
      success: false
    }

    this.handleForgetPassword = this.handleForgetPassword.bind(this)
  }

  async handleForgetPassword(event) {
    event.preventDefault();

    try {
      this.setState({ error: null })

      let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`, { email: this.state.email }, { headers: { 'Content-Type': 'application/json' } });

      if (data.success) this.setState({ success: 'Please check your email for the reset link' })
      else this.setState({ error: data.error })
    } catch (error) {
      console.error(error)
    }
  }
  
  render() {
    return (
      <div className='forgotPassword'>
        <div className="container">
          { this.state.error ? <Error message={this.state.error}></Error> : null }
          { this.state.success === false ? (
            <div className="form">
              <h1>Forgot Password</h1>

              <form onSubmit={this.handleForgetPassword}>
                <div className="mb-3">
                  <label className="form-label">Email Address</label>
                  <input type='email' value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} id="email" aria-describedby='email'/>
                </div>

                <div className="d-grid">
                  <button className="button" type='submit'>Submit</button>
                </div>
              </form>
            </div>
          ) : <Success message={this.state.success}></Success>}
        </div>
      </div>
    )
  }
}

export default ForgetPassword