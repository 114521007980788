import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Milestone from "./Milestone.json";

import "./TrackMap.scss";

export class TrackMap extends Component {
  constructor() {
    super();

    this.state = {
      tradeLicense: { status: false, step: 0 },
      tradeLicenseCorrection: { status: false, step: 0 },
      tradeLicenseRenewal: { status: false, step: 0 },
      bin: { status: false, step: 0 },
      tin: { status: false, step: 0 },
      dbid: { status: false, step: 0 },
      erc: { status: false, step: 0 },
      irc: { status: false, step: 0 },
      ircRenewal: { status: false, step: 0 },
      ercRenewal: { status: false, step: 0 },
      vatFiling: { status: false, step: 0 },
      companyFormation: { status: false, step: 0 },
      limitedPartnership: { status: false, step: 0, type: "" },
      copyright: { status: false, step: 0 },
      trademark: { status: false, step: 0 },
      taxFiling: { status: false, step: 0 },
      auditFiling: { status: false, step: 0 },
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.data == null) return;

      if (data.success) {
        this.setState({ loading: false });

        if (data.data.tradeLicense?.status)
          this.setState((ps) => ({
            ...ps,
            tradeLicense: {
              ...ps.tradeLicense,
              status: data.data.tradeLicense?.status,
              step: data.data.tradeLicense?.step,
            },
          }));

        if (data.data.tradeLicenseCorrection?.status)
          this.setState((ps) => ({
            ...ps,
            tradeLicenseCorrection: {
              ...ps.tradeLicenseCorrection,
              status: data.data.tradeLicenseCorrection?.status,
              step: data.data.tradeLicenseCorrection?.step,
            },
          }));

        if (data.data.tradeLicenseRenewal?.status)
          this.setState((ps) => ({
            ...ps,
            tradeLicenseRenewal: {
              ...ps.tradeLicenseRenewal,
              status: data.data.tradeLicenseRenewal?.status,
              step: data.data.tradeLicenseRenewal?.step,
            },
          }));

        if (data.data.bin?.status)
          this.setState((ps) => ({
            ...ps,
            bin: {
              ...ps.bin,
              status: data.data.bin?.status,
              step: data.data.bin?.step,
            },
          }));

        if (data.data.tin?.status)
          this.setState((ps) => ({
            ...ps,
            tin: {
              ...ps.tin,
              status: data.data.tin?.status,
              step: data.data.tin?.step,
            },
          }));

        if (data.data.dbid?.status)
          this.setState((ps) => ({
            ...ps,
            dbid: {
              ...ps.dbid,
              status: data.data.dbid?.status,
              step: data.data.dbid?.step,
            },
          }));

        if (data.data.erc?.status)
          this.setState((ps) => ({
            ...ps,
            erc: {
              ...ps.erc,
              status: data.data.erc?.status,
              step: data.data.erc?.step,
            },
          }));

        if (data.data.irc?.status)
          this.setState((ps) => ({
            ...ps,
            irc: {
              ...ps.irc,
              status: data.data.irc?.status,
              step: data.data.irc?.step,
            },
          }));

        if (data.data.ircRenewal?.status)
          this.setState((ps) => ({
            ...ps,
            ircRenewal: {
              ...ps.ircRenewal,
              status: data.data.ircRenewal?.status,
              step: data.data.ircRenewal?.step,
            },
          }));

        if (data.data.ercRenewal?.status)
          this.setState((ps) => ({
            ...ps,
            ercRenewal: {
              ...ps.ercRenewal,
              status: data.data.ercRenewal?.status,
              step: data.data.ercRenewal?.step,
            },
          }));

        if (data.data.vatFiling?.status)
          this.setState((ps) => ({
            ...ps,
            vatFiling: {
              ...ps.vatFiling,
              status: data.data.vatFiling?.status,
              step: data.data.vatFiling?.step,
            },
          }));

        if (data.data.companyFormation?.status)
          this.setState((ps) => ({
            ...ps,
            companyFormation: {
              ...ps.companyFormation,
              status: data.data.companyFormation?.status,
              step: data.data.companyFormation?.step,
            },
          }));

        if (data.data.limitedPartnership?.status)
          this.setState((ps) => ({
            ...ps,
            limitedPartnership: {
              ...ps.limitedPartnership,
              status: data.data.limitedPartnership?.status,
              step: data.data.limitedPartnership?.step,
              type: data.data.limitedPartnership?.typeOfPartnership,
            },
          }));

        if (data.data.copyright?.status)
          this.setState((ps) => ({
            ...ps,
            copyright: {
              ...ps.copyright,
              status: data.data.copyright?.status,
              step: data.data.copyright?.step,
            },
          }));

        if (data.data.trademark?.status)
          this.setState((ps) => ({
            ...ps,
            trademark: {
              ...ps.trademark,
              status: data.data.trademark?.status,
              step: data.data.trademark?.step,
            },
          }));

        if (data.data.taxFiling?.status)
          this.setState((ps) => ({
            ...ps,
            taxFiling: {
              ...ps.taxFiling,
              status: data.data.taxFiling?.status,
              step: data.data.taxFiling?.step,
            },
          }));

        if (data.data.auditFiling?.status)
          this.setState((ps) => ({
            ...ps,
            auditFiling: {
              ...ps.auditFiling,
              status: data.data.auditFiling?.status,
              step: data.data.auditFiling?.step,
            },
          }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="TrackMap">
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="milestones">
            {this.state.tradeLicense.status ? (
              <div className="service">
                <div className="heading">Trade License</div>
                <div className="milestone">
                  - {Milestone["tradeLicense"][this.state.tradeLicense.step]}
                </div>
              </div>
            ) : null}

            {this.state.tradeLicenseCorrection.status ? (
              <div className="service">
                <div className="heading">Trade License Correction</div>
                <div className="milestone">
                  -{" "}
                  {
                    Milestone["tradeLicense"][
                      this.state.tradeLicenseCorrection.step
                    ]
                  }
                </div>
              </div>
            ) : null}

            {this.state.tradeLicenseRenewal.status ? (
              <div className="service">
                <div className="heading">Trade License Renewal</div>
                <div className="milestone">
                  -{" "}
                  {
                    Milestone["tradeLicense"][
                      this.state.tradeLicenseRenewal.step
                    ]
                  }
                </div>
              </div>
            ) : null}

            {this.state.bin.status ? (
              <div className="service">
                <div className="heading">BIN</div>
                <div className="milestone">
                  - {Milestone["bin"][this.state.bin.step]}
                </div>
              </div>
            ) : null}

            {this.state.tin.status ? (
              <div className="service">
                <div className="heading">TIN</div>
                <div className="milestone">
                  - {Milestone["tin"][this.state.tin.step]}
                </div>
              </div>
            ) : null}

            {this.state.limitedPartnership.status ? (
              <div className="service">
                <div className="heading">Registered Partnership</div>
                <div className="milestone">
                  -{" "}
                  {
                    Milestone[this.state.limitedPartnership.type][
                      this.state.limitedPartnership.step
                    ]
                  }
                </div>
              </div>
            ) : null}

            {this.state.dbid.status ? (
              <div className="service">
                <div className="heading">DBID</div>
                <div className="milestone">
                  - {Milestone["dbid"][this.state.dbid.step]}
                </div>
              </div>
            ) : null}

            {this.state.erc.status ? (
              <div className="service">
                <div className="heading">ERC</div>
                <div className="milestone">
                  - {Milestone["erc"][this.state.erc.step]}
                </div>
              </div>
            ) : null}

            {this.state.irc.status ? (
              <div className="service">
                <div className="heading">IRC</div>
                <div className="milestone">
                  - {Milestone["irc"][this.state.irc.step]}
                </div>
              </div>
            ) : null}

            {this.state.ercRenewal.status ? (
              <div className="service">
                <div className="heading">ERC Renewal</div>
                <div className="milestone">
                  - {Milestone["ercRenewal"][this.state.ercRenewal.step]}
                </div>
              </div>
            ) : null}

            {this.state.ircRenewal.status ? (
              <div className="service">
                <div className="heading">IRC Renewal</div>
                <div className="milestone">
                  - {Milestone["ircRenewal"][this.state.ircRenewal.step]}
                </div>
              </div>
            ) : null}

            {this.state.copyright.status ? (
              <div className="service">
                <div className="heading">Copyright</div>
                <div className="milestone">
                  - {Milestone["copyright"][this.state.copyright.step]}
                </div>
              </div>
            ) : null}

            {this.state.trademark.status ? (
              <div className="service">
                <div className="heading">Trademark</div>
                <div className="milestone">
                  - {Milestone["trademark"][this.state.trademark.step]}
                </div>
              </div>
            ) : null}

            {this.state.taxFiling.status ? (
              <div className="service">
                <div className="heading">Tax Filing</div>
                <div className="milestone">
                  - {Milestone["taxfiling"][this.state.taxFiling.step]}
                </div>
              </div>
            ) : null}

            {this.state.auditFiling.status ? (
              <div className="service">
                <div className="heading">Audit Filing</div>
                <div className="milestone">
                  - {Milestone["auditfiling"][this.state.auditFiling.step]}
                </div>
              </div>
            ) : null}

            {this.state.vatFiling.status ? (
              <div className="service">
                <div className="heading">Per Month Vat Filing</div>
                <div className="milestone">
                  - {Milestone["perMonthVatFiling"][this.state.vatFiling.step]}
                </div>
              </div>
            ) : null}

            {this.state.companyFormation.status ? (
              <div className="service">
                <div className="heading">Company Formation</div>
                <div className="milestone">
                  -{" "}
                  {
                    Milestone["companyFormation"][
                      this.state.companyFormation.step
                    ]
                  }
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default TrackMap;
