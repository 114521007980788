import React, { Component } from 'react'

export class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem('token')
    localStorage.removeItem('business')
    localStorage.removeItem('user-id')
    localStorage.removeItem('businessForm')
    return window.location.href = '/login'
  }
  render() {
    return (
      <div>Logging out...</div>
    )
  }
}

export default Logout