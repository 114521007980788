import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Error from "../../components/error/error";
import Success from "../../components/error/success";

import Milestone from "../../components/dashboard/Milestone.json";

import "./trackmap.scss";

export class Copyright extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      status: false,
      step: 0,
      data: "",
      typeOfCopyright: "Literary/dramatic work",
      passportPicture: "",
      nid: "",
      aoa: "",
      incorporationCertificate: "",
      partnershipAgreement: "",
      tradeLicense: "",
      tinCertificate: "",
      error: null,
      success: null,
      transIDStat: true,
      transID: "",
    };

    this.handleCopyright = this.handleCopyright.bind(this);
    this.handleTransID = this.handleTransID.bind(this);
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/business/get`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (data.success) {
        if (
          data.data.copyright?.transactionID === "" ||
          !data.data.copyright?.transactionID
        )
          this.setState({ transIDStat: true });
        else this.setState({ transIDStat: false });
        this.setState({
          status: data.data.copyright?.status,
          businessType: data.data.businessType,
          step: data.data.copyright?.step,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleCopyright(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("typeOfCopyright", this.state.typeOfCopyright);
    formData.append("passportPicture", this.state.passportPicture);
    formData.append("nid", this.state.nid);
    if (this.state.aoa !== "") formData.append("aoa", this.state.aoa);
    if (this.state.incorporationCertificate !== "")
      formData.append(
        "incorporationCertificate",
        this.state.incorporationCertificate
      );
    if (this.state.partnershipAgreement !== "")
      formData.append("partnershipAgreement", this.state.partnershipAgreement);
    formData.append("tradeLicense", this.state.tradeLicense);
    formData.append("tinCertificate", this.state.tinCertificate);
    formData.append("token", localStorage.getItem("token"));

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/copyright`,
      formData
    );
    if (data.success) {
      this.setState({ status: true });
      this.setState({ success: "Copyright documents submitted" });
      this.setState({ step: 0 });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/service/copyright";
    }
  }

  async handleTransID(event) {
    event.preventDefault();

    this.setState({ transIDStat: false });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/transaction`,
      {
        token: localStorage.getItem("token"),
        service: "copyright",
        transID: this.state.transID,
      }
    );

    if (data.success) {
      this.setState({ success: `Transaction ID have been submitted` });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
    } else {
      this.setState({ error: "Something went wrong. Please try again." });
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">Copyright</h1>
            {this.state.status ? (
              <div className="track-map">
                {Milestone["copyright"].map((ms, index) => {
                  return (
                    <div className="timeline-item" key={index}>
                      <div className="milestone-left">
                        <div className="vertical-divider-container">
                          <div
                            className={
                              "vertical-divider " + (index === 11 ? "last" : "")
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className={
                          "invite-subtitle " +
                          (index <= this.state.step ? "active " : "")
                        }
                      >
                        <h5>{ms}</h5>
                        {this.state.step === 3 &&
                        index === 3 &&
                        this.state.transIDStat ? (
                          <div className="transaction my-2">
                            <form onSubmit={this.handleTransID}>
                              <input
                                placeholder="Transaction ID"
                                type="text"
                                onChange={(event) =>
                                  this.setState({ transID: event.target.value })
                                }
                              ></input>
                              <button className="button" type="submit">
                                Submit
                              </button>
                            </form>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="form">
                <form onSubmit={this.handleCopyright}>
                  <label>Type of the copyright*</label>
                  <select
                    value={this.state.typeOfCopyright}
                    onChange={(event) =>
                      this.setState({ typeOfCopyright: event.target.value })
                    }
                    required
                  >
                    <option value="Literary/dramatic work">
                      Literary/dramatic work
                    </option>
                    <option value="Musical work">Musical work</option>
                    <option value="Computer Software">Computer Software</option>
                    <option value="Cinematograph Film">
                      Cinematograph Film
                    </option>
                    <option value="Artistic Work">Artistic Work</option>
                    <option value="Sound recording">Sound recording</option>
                    <option value="Television broadcasting">
                      Television broadcasting
                    </option>
                    <option value="Radio broadcasting">
                      Radio broadcasting
                    </option>
                    <option value="Website, e-mail and any other medium including electronic communication">
                      Website, e-mail and any other medium including electronic
                      communication
                    </option>
                  </select>

                  <label>Passport size picture*</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ passportPicture: event.target.files[0] })
                    }
                    required
                  />

                  <label>National ID*</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ nid: event.target.files[0] })
                    }
                    required
                  />

                  <label>
                    Article of Association & Memorandum of Association (If
                    applicable)
                  </label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ aoa: event.target.files[0] })
                    }
                  />

                  <label>Incorporation Certificate (If applicable).</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({
                        incorporationCertificate: event.target.files[0],
                      })
                    }
                  />

                  <label>Partnership Agreement (If applicable).</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({
                        partnershipAgreement: event.target.files[0],
                      })
                    }
                  />

                  <label>Trade License*</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ tradeLicense: event.target.files[0] })
                    }
                  />

                  <label>Tin Certificate*</label>
                  <input
                    type="file"
                    onChange={(event) =>
                      this.setState({ tinCertificate: event.target.files[0] })
                    }
                  />

                  <button className="button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Copyright;
