import React, { Component } from 'react'
import { Field } from 'react-final-form'

export default class CompanyName extends Component {
  render() {
    return (
      <div className='company-name'>
        <h1>Mention the name of your company</h1>
        <p>Type in the desired name of your company. We will let you know whether it is available for use</p>
        <Field name="companyName">
          {({ input }) => <input placeholder='Company Name' type='text' {...input} />}
        </Field>
      </div>
    )
  }
}
