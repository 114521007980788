import React, { Component } from 'react'
import { Field } from 'react-final-form'

export default class BusinessActivity extends Component {
  render() {
    return (
      <div className='business-activity'>
        <h1>Welcome {this.props.name}</h1>
        <h1>Tell us about your business</h1>
        <p>In order to start a business in Bangladesh, you need to let the government know what your company does</p >
        <Field name='industry'>
          {({ input }) => <input placeholder='Business Activity' type='text' {...input} />}
        </Field>
      </div>
    )
  }
}
