import React, { Component } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

import Error from "../components/error/error";
import Success from "../components/error/success";

import "./services/tradeLicense.scss";

export class PaymentGateway extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      businessName: "",
      typeOfBusiness: "eCommerce",
      websitePage: "",
      legalEntity: "Sole Proprietor",
      error: null,
      success: null,
    };

    this.handlePaymentGateway = this.handlePaymentGateway.bind(this);
  }

  async handlePaymentGateway(event) {
    event.preventDefault();

    this.setState({ loading: true });

    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/business/payment-gateway`,
      {
        businessName: this.state.businessName,
        typeOfBusiness: this.state.typeOfBusiness,
        websitePage: this.state.websitePage,
        legalEntity: this.state.legalEntity,
        token: localStorage.getItem("token"),
      }
    );

    if (data.success) {
      this.setState({
        success: "Request for payment gateway submitted",
        loading: false,
      });
      setTimeout(() => {
        this.setState({ success: null });
      }, 4000);
      window.location.href = "/payment-gateway";
    }
  }

  render() {
    return (
      <div className="trade-license">
        {this.state.error ? <Error message={this.state.error}></Error> : null}
        {this.state.success ? (
          <Success message={this.state.success}></Success>
        ) : null}
        {this.state.loading ? (
          <div className="spinner">
            <SpinnerCircular color="rgba(89, 61, 184, 255)"></SpinnerCircular>
          </div>
        ) : (
          <div className="container">
            <h1 className="serviceHeading">Request for Payment Gateway</h1>
            <div className="form">
              <form onSubmit={this.handlePaymentGateway}>
                <label>Business Name*</label>
                <input
                  type="text"
                  required
                  onChange={(event) =>
                    this.setState({ businessName: event.target.value })
                  }
                ></input>

                <label>Type of business*</label>
                <select
                  defaultValue={this.state.typeOfBusiness}
                  onChange={(event) =>
                    this.setState({ typeOfBusiness: event.target.value })
                  }
                  required
                >
                  <option value="eCommerce">eCommerce</option>
                  <option value="fCommerce (Facebook Page)">
                    fCommerce (Facebook Page)
                  </option>
                  <option value="Information Technology">
                    Information Technology
                  </option>
                  <option value="Charity / Donation">Charity / Donation</option>
                  <option value="Service Platform (Web & App based)">
                    Service Platform (Web & App based)
                  </option>
                  <option value="Gaming (App based)">Gaming (App based)</option>
                  <option value="Membership System">Membership System</option>
                  <option value="Event Ticket / Registration">
                    Event Ticket / Registration
                  </option>
                  <option value="Consultancy Service">
                    Consultancy Service
                  </option>
                  <option value="Health Service (Appointment / Consultancy)">
                    Health Service (Appointment / Consultancy)
                  </option>
                  <option value="Others">Others</option>
                </select>

                <label>Website/Facebook Page</label>
                <input
                  type="text"
                  required
                  onChange={(event) =>
                    this.setState({ websitePage: event.target.value })
                  }
                ></input>

                <label>Legal Entity</label>
                <select
                  defaultValue={this.state.typeOfBusiness}
                  onChange={(event) =>
                    this.setState({ legalEntity: event.target.value })
                  }
                  required
                >
                  <option value="Sole Proprietor">Sole Proprietor</option>
                  <option value="Non-Profit">Non-Profit</option>
                  <option value="Partnership">Partnership</option>
                  <option value="Government Agency">Government Agency</option>
                  <option value="Private Limited">Private Limited</option>
                  <option value="Educational Institution">
                    Educational Institution
                  </option>
                  <option value="Public Limited">Public Limited</option>
                  <option value="Religious Entity">Religious Entity</option>
                  <option value="NGO">NGO</option>
                </select>

                <button className="button" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PaymentGateway;
