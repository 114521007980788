import { Component } from "react";

import './error.css'

export default class Success extends Component {
  render() {
    return (
      <div className="success">
        <p>{this.props.message}</p>
      </div>
    )
  }
}